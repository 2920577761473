import React from 'react'
import { Link } from 'react-router-dom'

function Samples_widget() {

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <div id="right-hand-side-container">
        <div className="single-widget category-widget shadow">
          <h4 className="ml-3">Samples</h4>
          <ul>
            <li>
              <Link className="justify-content-between align-items-center d-flex" to="/essay" onClick={goToTop}>
                <h6 className="">Eassy</h6>
                <span className="">1</span>
              </Link>
            </li>
            <li>
              <Link className="justify-content-between align-items-center d-flex" to="/dissertation" onClick={goToTop}>
                <h6 className="">Dissertation</h6>
                <span className="">5</span>
              </Link>
            </li>
            <li>
              <Link className="justify-content-between align-items-center d-flex" to="/programming" onClick={goToTop}>
                <h6 className="">Programming</h6>
                <span className="">9</span>
              </Link>
            </li>
            <li>
              <Link className="justify-content-between align-items-center d-flex" to="/networking" onClick={goToTop}>
                <h6 className="">Networking</h6>
                <span className="">16</span>
              </Link>
            </li>
            <li>
              <Link className="justify-content-between align-items-center d-flex" to="/research" onClick={goToTop}>
                <h6 className="">Research</h6>
                <span className="">2</span>
              </Link>
            </li>
            <li>
              <Link className="justify-content-between align-items-center d-flex" to="/cdr" onClick={goToTop}>
                <h6 className="">CDR</h6>
                <span className="">2</span>
              </Link>
            </li>
            <li>
              <Link className="justify-content-between align-items-center d-flex" to="/proofreading" onClick={goToTop}>
                <h6 className="">Proof Of Reading</h6>
                <span className="">1</span>
              </Link>
            </li>
            <li>
              <Link className="justify-content-between align-items-center d-flex" to="/mba" onClick={goToTop}>
                <h6 className="">MBA</h6>
                <span className="">1</span>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  )
}

export default Samples_widget
