import React from 'react'
import { Link } from 'react-router-dom'
import './Samples_submenu.css'
import Samples_widget from '../Samples_widget/Samples_widget'
import { ImSearch } from 'react-icons/im'

function Samples_submenu() {

  return (
    <>
      {/* <!-- Header Start --> */}
      <div class="container-fluid bg-primary mb-5">
        <div
          class="d-flex flex-column align-items-center justify-content-center"
          style={{ minHeight: "400px" }}
        >
          <h3 class="display-3 font-weight-bold text-white">
            Networking
          </h3>
          <div class="d-inline-flex text-white">
            <p class="m-0">
              <Link class="text-white" to="/">
                Home
              </Link>
            </p>
            <p class="m-0 px-2">/</p>
            <p class="m-0">
              <Link class="text-white" to="/samples">
                Sample's
              </Link>
            </p>
            <p class="m-0 px-2">/</p>
            <p class="m-0">Networking</p>
          </div>
        </div>
      </div>
      {/* <!-- Header End --> */}

      <section className="blog-posts-area section-gap" style={{ textAlign: 'justify' }}>
        <div className="container">
          <div className="row d-flex justify-content-center text-center">
            <div className="col-md-8 pb-40 header-text">
              <h1>Recent Questions And Assignment Topics For Networking</h1>
            </div>
          </div>

          <div className="row mt-5">
            <div
              className="col-lg-8 post-list blog-post-list"
              id="container-content-wrapper"
            >
              <div className="single-post single-heading">
                <div className="post mb-4">
                  <Link>
                    <h4>Computer Networks &amp; Operating Systems</h4>
                  </Link>
                  <div className="d-flex flex-row  mt-3">
                    <div className="p-2 related card cardcolor">Course Code: KF7023 </div>
                    <div className="p-2 ml-2 related card cardcolor">
                      University: Northumbria University{" "}
                    </div>
                    <div className="p-2 ml-2 related card cardcolor">Country: Australia </div>
                  </div>
                  <p className="text-justify mt-2">
                    1. Evaluation Report You are one of a team of newly graduated
                    software engineers working on a project to build a distributed
                    information system for a business of your own choice that would
                    employ new and emerging technologies. It is preferable to choose a
                    business of your own choice though you can choose a business from
                    the examples given below as well (in Appendix I). In each entry,
                    choose one topic from each of the two subjects (in Appendix II).
                    Write an Evaluation Report for each entry.
                  </p>

                  <div className="clearfix" />
                </div>
                <div className="post mb-4 mt-3">
                  <a>
                    <h4>Internetworking with TCPIP</h4>
                  </a>
                  <div className="d-flex flex-row  mt-3">
                    <div className="p-2 related card cardcolor">Course Code: ITC542 </div>
                    <div className="p-2 ml-2 related card cardcolor">
                      University: Charles Strut University{" "}
                    </div>
                    <div className="p-2 ml-2 related card cardcolor">Country: UK </div>
                  </div>
                  <p className="text-justify mt-2">
                    AA Assessment Task This assessment aims to develop and gauge
                    student understanding of the key topics covered so far by
                    answering the following questions. Answering these questions will
                    help you build some understanding for the next assessment item as
                    well as for the entire subject. It is expected that answers to the
                    assignment questions be succinct (i.e. precise and concise) with
                    all sources of information fully referenced as per APA referencing
                    style. You have to reference the text book and any{" "}
                  </p>

                  <div className="clearfix" />
                </div>
                <div className="post mb-4 mt-3">
                  <a>
                    <h4>Wireless Networking Concepts</h4>
                  </a>
                  <div className="d-flex flex-row  mt-3">
                    <div className="p-2 related card cardcolor">Course Code: ITC513 </div>
                    <div className="p-2 ml-2 related card cardcolor">
                      University: Charles Strut{" "}
                    </div>
                    <div className="p-2 ml-2 related card cardcolor">Country: UK </div>
                  </div>
                  <p className="text-justify mt-2">
                    AA Assessment ITC513 201960 &nbsp; Task 1 (10 Marks) In a wireless
                    communication system, antennas play a crucial role at both
                    transmitter and receiver sides. Some of the important design
                    parameters used to measure the performance of an antenna are
                    Directivity, Gain, Efficiency and Radiation Patterns. Explain with
                    reasonable examples, what is Antenna Directivity, Gain, Efficiency
                    and Radiation Patterns. You may consider providing mathematical
                    expressions as well as figures for the explanati{" "}
                  </p>

                  <div className="clearfix" />
                </div>
                <div className="post mb-4 mt-3">

                  <a>
                    <h4>Data Analytics for intrusion detection</h4>
                  </a>
                  <div className="d-flex flex-row  mt-3">
                    <div className="p-2 related card cardcolor">Course Code: MN623 </div>
                    <div className="p-2 ml-2 related card cardcolor">
                      University: Melbourne Institute of Technology{" "}
                    </div>
                    <div className="p-2 ml-2 related card cardcolor">Country: Australia </div>
                  </div>
                  <p className="text-justify mt-2">
                    Assignment Overview For this assignment, you will analyses and
                    evaluate one of the publicly available Network Intrusion datasets
                    given in Table 1. Your task is to complete and make a research
                    report based on the following: 1- Discuss all the attacks on your
                    selected public intrusion dataset. 2- Perform intrusion detection
                    using the available data analytic techniques using WEKA or other
                    platforms.
                  </p>

                  <div className="clearfix" />
                </div>
                <div className="post mb-4 mt-3">

                  <a>
                    <h4>Wireless Networks and Security</h4>
                  </a>
                  <div className="d-flex flex-row  mt-3">
                    <div className="p-2 related card cardcolor">Course Code: MN603 </div>
                    <div className="p-2 ml-2 related card cardcolor">
                      University: Melbourne school of Information Technology and
                      Engineering{" "}
                    </div>
                    <div className="p-2 ml-2 related card cardcolor">Country: Australia </div>
                  </div>
                  <p className="text-justify mt-2">
                    Assignment Description Smart home or home automation technology
                    provides homeowners’ security, comfort, convenience and energy
                    efficiency by enabling them to control smart devices, via an app
                    on their smartphone or another networked device. This technology
                    enables you to do things such as turn the lights on/off, stream
                    music to a speaker, lock/open the doors remotely, and much more.
                    This assignment aims to simulate a smart home using IoT technology
                    in Cisco Packet Tracer.
                  </p>

                  <div className="clearfix" />
                </div>
                <div className="post mb-4 mt-3">

                  <a>
                    <h4>File system and Advanced scripting</h4>
                  </a>
                  <div className="d-flex flex-row  mt-3">
                    <div className="p-2 related card cardcolor">Course Code: ITC 514 </div>
                    <div className="p-2 ml-2 related card cardcolor">University: CSU </div>
                    <div className="p-2 ml-2 related card cardcolor">Country: Australia </div>
                  </div>
                  <p className="text-justify mt-2">
                    Objective To present a detailed review of a file system and
                    advanced scripting schemes by using different platforms.
                  </p>

                  <div className="clearfix" />
                </div>
                <div className="post mb-4 mt-3">

                  <a>
                    <h4>ICT Systems Administration Fundamentals</h4>
                  </a>
                  <div className="d-flex flex-row  mt-3">
                    <div className="p-2 related card cardcolor">Course Code: KIT501 </div>
                    <div className="p-2 ml-2 related card cardcolor">
                      University: University of Tasmania{" "}
                    </div>
                    <div className="p-2 ml-2 related card cardcolor">Country: Australia </div>
                  </div>
                  <p className="text-justify mt-2">
                    KIT501 Assignment 2– UNIX Shell Programming (10% of your KIT501
                    final result) Introduction This is an individual assignment. There
                    are two (2) shell programming tasks in this assignment that use
                    skills and knowledge you have learned from the unit's practical
                    sessions. You are required to make a directory named kit501a2
                    under your home directory (on alacritas) and use kit501a2 as your
                    working directory for this assignment.
                  </p>

                  <div className="clearfix" />
                </div>
                <div className="post mt-3 mb-4">
                  <a>
                    <h4>Data Wrangling</h4>
                  </a>
                  <div className="d-flex flex-row  mt-3">
                    <div className="p-2 related card cardcolor">
                      Course Code: SET11121 / SET11521{" "}
                    </div>
                    <div className="p-2 ml-2 related card cardcolor">
                      University: Edinburgh Napier University{" "}
                    </div>
                    <div className="p-2 ml-2 related card cardcolor">Country: UK </div>
                  </div>
                  <p className="text-justify mt-2">
                    Assessment Brief The assignment aims to cover the learning
                    outcomes specified for the module: LO1: Critically evaluate the
                    tools and techniques of the data storage, interfacing, aggregation
                    and processing LO2: Select and apply a range of specialised data
                    types, tools and techniques for data storage, interfacing,
                    aggregation and processing LO3: Employ specialised techniques for
                    dealing with complex data sets LO4: Design, develop and critically
                    evaluate data driven applications.
                  </p>

                  <div className="clearfix" />
                </div>
                <div className="post mt-3 mb-4">
                  <a>
                    <h4>Network design-assessment 2</h4>
                  </a>
                  <div className="d-flex flex-row  mt-3">
                    <div className="p-2 related card cardcolor">Course Code: COIT 20264 </div>
                    <div className="p-2 ml-2 related card cardcolor">University: CQUniversity </div>
                    <div className="p-2 ml-2 related card cardcolor">Country: Australia </div>
                  </div>
                  <p className="text-justify mt-2">
                    Objectives This assessment task relates to Unit Learning Outcomes
                    3 to 5 and can be undertaken in a group of up to 4 students or
                    individually. Distance students can form groups with on-campus
                    students as well. In this assessment task, you will complete your
                    final network design report for the scenario given on page 3 based
                    on the feedback you received on your Assessment Item 1 report.
                  </p>

                  <div className="clearfix" />
                </div>
                <div className="post no-border-bottom">
                  <a>
                    <h4>Assessment 1- Network design</h4>
                  </a>
                  <div className="d-flex flex-row  mt-3">
                    <div className="p-2 related card cardcolor">Course Code: COIT 20264 </div>
                    <div className="p-2 ml-2 related card cardcolor">
                      University: CQ University{" "}
                    </div>
                    <div className="p-2 ml-2 related card cardcolor">Country: Australia </div>
                  </div>
                  <p className="text-justify mt-2">
                    Objectives This assessment task relates to Unit Learning Outcomes
                    1 to 5 and can be undertaken individually or in a group of up to 4
                    students. Distance students can form groups with on-campus
                    students as well. Using top-down network design methodology, you
                    will develop your logical network design based on the scenario
                    given on page 5 of this document. You may make any justifiable
                    assumptions but you should mention them wherever you use. You are
                    also expected to contribute to the Group Discuss.
                  </p>

                  <div className="clearfix" />
                </div>

              </div>
            </div>
            <div className="col-lg-4 sidebar">
              <div>
                <div id="right-hand-side-container">
                  <Samples_widget />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  )
}

export default Samples_submenu
