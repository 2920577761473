import React from "react";
import dis_banner from '../../../assets/img/newHome/dissersation_banner.png'
import './Dissertation_service.css'
import writing_img from '../../../assets/img/newHome/writing_img.png'
import step from '../../../assets/img/newHome/step.webp'

function Dissertation_service() {
  return (
    <>

      <div className="disser_banner">
        <div className="container">
          <div className="row ">
            <div className="col-md-7 col-lg-7 col-xs-12 col-sm-12 col-xl-7">
              <p className="header_font" style={{ fontSize: 67 }}>Get the <span style={{ color: '#FEC00F' }}>Qualified Dissertation </span>Writers</p>
              <p className="header_sub_font pb-4">Dissertation is considered to be the most effective component of several courses. The dissertation covers the submission of an efficient piece of writing. It enables discovering the way students could gain enhanced potential via their writing skills. The dissertation requires sufficient time to be prepared well in a structured manner.</p>
              <a href="/contact" className="get_started ">Contact Us&nbsp;&nbsp;
                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="14" viewBox="0 0 17 14" fill="none">
                  <path d="M16.7923 7.49522L10.4175 13.7949C10.2846 13.9262 10.1043 14 9.91634 14C9.72838 14 9.54812 13.9262 9.41521 13.7949C9.2823 13.6635 9.20764 13.4854 9.20764 13.2996C9.20764 13.1139 9.2823 12.9358 9.41521 12.8044L14.5814 7.69996H0.70831C0.520455 7.69996 0.340293 7.62621 0.207459 7.49495C0.0746255 7.36368 0 7.18564 0 7C0 6.81436 0.0746255 6.63632 0.207459 6.50505C0.340293 6.37378 0.520455 6.30004 0.70831 6.30004H14.5814L9.41521 1.19557C9.2823 1.06423 9.20764 0.886095 9.20764 0.70035C9.20764 0.514606 9.2823 0.336469 9.41521 0.205128C9.54812 0.0737866 9.72838 0 9.91634 0C10.1043 0 10.2846 0.0737866 10.4175 0.205128L16.7923 6.50478C16.8581 6.56978 16.9104 6.64698 16.946 6.73196C16.9817 6.81693 17 6.90801 17 7C17 7.09198 16.9817 7.18307 16.946 7.26804C16.9104 7.35302 16.8581 7.43021 16.7923 7.49522Z" fill="black" />
                </svg></a>
            </div>
            <div className="col-md-5 col-lg-5 col-xs-12 col-sm-12 col-xl-5">
              <img src={dis_banner} className="about_banner_img" />
            </div>
          </div>
        </div>
      </div>

      <div className="bg-black">
        <div className="row">
          <div className="col-md-3 col-sm-12 col-lg-3 col-xl-3 col-xs-12">
            <p className="number">12,958</p>
            <p className="request_text">Student requests</p>
          </div>
          <div className="col-md-3 col-sm-12 col-lg-3 col-xl-3 col-xs-12">
            <p className="number">2,468</p>
            <p className="request_text">Assignments completed</p>
          </div>
          <div className="col-md-3 col-sm-12 col-lg-3 col-xl-3 col-xs-12">
            <p className="number">2,378</p>
            <p className="request_text">Technical projects</p>
          </div>
          <div className="col-md-3 col-sm-12 col-lg-3 col-xl-3 col-xs-12">
            <p className="number">1,273</p>
            <p className="request_text">In house professionals</p>
          </div>
        </div>
      </div>

      <div className="container">
        <p className="heading_reason mt-4 text-center">Reason for availing dissertation service help </p>
        <div className="row" style={{ padding: 15 }}>
          <p className="header_sub_font">Writing a dissertation is considered to be the most complex task completed by students. Whereas, it plays a vital role in the life of students academically. It provides a chance for students to learn several things while preparing of dissertation. Writing of dissertation could not be easily accomplished as it is considered to be a little tricky. So, there are times when students may feel difficulty in executing the proper way to write an appropriate dissertation. During this time, dissertation services play a vital role in reducing the burden on students.<br></br><br></br>
            Availing dissertation service help enable in contribute a major role in students’ final grade. It also plays a significant role in several higher studies. The tutor always focuses on ensuring an appropriate structure with proper formatting so that you can get good marks. At Assignment Service Australia, our dissertation team is capable of delivering various outstanding services.<br></br><br></br>
            Giving the responsibility to ASA, one of the most efficient brands of writing dissertations, we emphasise taking care of all the aspects beginning from generating to executing the idea. However, we have a highly professional team who take enhanced care of writing quality dissertations. The dissertation mainly begins with researching and later on, ends with checking for plagiarism. Professional and highly dedicated writers play a major role in the complete process. Therefore, we aim to provide the best dissertation services that any student could easily opt for without any hassle.
          </p>

          <p className="heading_reason">Why us?</p>
          <p className="header_sub_font">Before beginning to start working on the dissertation, it is necessary to have a long dissertation proposal which is required to be approved by the tutor for approving the topic. It could be a very complex task for students. It could result in leaving students panicking at the thought of proposal rejection. But we emphasise opting for the best dissertation writing service, you could worry less as Assignment Service Australia facilitates in providing best services. The experts at ASA not only enable in helping you select the topic but also help in writing up a complete dissertation proposal.
            <br></br><br></br>Assignment Service Australia is a renowned name in the education sector for offering high-quality assistance to students based in different areas. We have been continuously serving the best dissertation services to students over many years.
          </p>

        </div>
      </div>

      <div className="mt-4">

        {/* <p className="header_font mt-4" style={{ color: '#FEC00F', textAlign: 'center' }}>Dissertation <span style={{ color: '#000' }}>Writing</span></p> */}

        <div className="row" style={{ paddingLeft: '10%', paddingRight: '10%' }}>
          <p className="heading_reason text-center">Benefits of Getting Dissertation Services from ASA</p>
          <p className="header_sub_font">Apart from several services offered by ASA, there is also one thing that enablesit to stand out from the crowd. Do you know what? We offer 100% quality work which enables students to receive good grades. Whenever students place their requirements in front of us, we emphasiseassigning the specific task to a suitable professional. Our professionals ensure to conduct deep research from various reliable sources while complying with different standards of the university.
            <br></br><br></br> We understand that the life of a student is full of struggles and tight budgets. However, we emphasise delivering valuable services to students at reasonable prices so that they can avail of any services at any time in an affordable manner. We also facilitate offering several offers and discounts to students that enable us to make our services more effective and highly reasonable as compared to other competitors in market.
          </p>
          <div className="col-md-5 col-lg-5 col-sm-12 col-xl-5 col-xs-12 ">
            <div>
              <p className="step_text mt-4" >Qualified <span style={{ color: '#FEC00F' }}>Dissertation Writers</span></p>
              <p className="writing_sub_text">We have highly educated and qualified writers to help students. Every writer either holds a Ph.D. or an equivalent degree in their respective fields. Our organization hires individuals which have at least 2 or more years of experience in dissertation writing. </p>
            </div>
            <div className="padding_top">
              <p className="step_text mt-4" >Affordable <span style={{ color: '#FEC00F' }}>prices</span></p>
              <p className="writing_sub_text">Our motto and aim are to help students with dissertation writing. A lot of thinking and research has gone into deciding the prices for our services. Our prices are quite affordable as compared to the market. We provide high quality at a cheaper price comparatively.</p>
            </div>
            <div className="padding_top">
              <p className="step_text mt-4" >Timely <span style={{ color: '#FEC00F' }}>delivery</span></p>
              <p className="writing_sub_text">We only take up the work which can be submitted on time only. We understand that any delay in the delivery of the task can be very critical for the student. Our managers keep a track of the work on an hourly basis to make sure it is delivered on time. Majority of the time we are able to deliver the tasks well before the deadline.</p>
            </div>
          </div>
          <div className="col-md-2 col-lg-2 col-sm-12 col-xl-2 col-xs-12 writing_img" >
            <img src={writing_img} />
          </div>
          <div className="col-md-5 col-lg-5 col-sm-12 col-xl-5 col-xs-12 ">
            <div className="padding_top" >
              <p className="step_text mt-4" >Custom <span style={{ color: '#FEC00F' }}>Written</span></p>
              <p className="writing_sub_text">Our content precisely meets the requirement of the client. Meeting the exact requirements of the clients is our top priority. All the assignments go through a certain check where it is made sure all the requirements of the client are met. We have writers which are fully equipped to work according to any given scenario or set of instructions.</p>
            </div >
            <div className="padding_top" >
              <p className="step_text mt-4" >24/7 <span style={{ color: '#FEC00F' }}>Support</span></p>
              <p className="writing_sub_text">We provide 24/7 services. We are actively available and respond to requests instantly. We work endlessly to help students with dissertation writing. You can expect a timely response from us at any given time irrespective of different time zones. We understand the importance and gravity of students’ time.</p>
            </div>
            <div className="padding_top" >
              <p className="step_text mt-4" >plagiarism <span style={{ color: '#FEC00F' }}>Free</span></p>
              <p className="writing_sub_text">All of our work is free of plagiarism. Every assignment is checked through turnitin.com to make sure there is minimal plagiarism. Our experienced dissertation writers provide unique content which ensures the achievement of high grades.</p>
            </div>
          </div>
        </div>
      </div>

      <div className="process_sec ">
        <p className="grades pt-5">Easy<span style={{ color: '#fff' }}>&nbsp;3-step</span>Process</p>
        <div className="container">
          <div className="row" style={{ marginTop: 100, paddingBottom: 100 }}>
            <div className="col-md-3 col-sm-12 col-lg-3 col-xs-12 col-xl-3 adjust">
              <div>
                <p className="step_text">Contact Us</p>
                <p className="step_sub_text">Contact us via the button below.</p>
              </div>
              <div>
                <p className="step_text">Get the experts</p>
                <p className="step_sub_text">Our team will review the form, get a expert for your assignment.</p>
              </div>
            </div>
            <div className="col-md-1 col-sm-12 col-lg-1 col-xs-12 col-xl-1 "></div>
            <div className="col-md-4 col-sm-12 col-lg-4 col-xs-12 col-xl-4" style={{ display: 'flex', justifyContent: 'space-evenly' }}>
              <img src={step} className="step_img"/>
            </div>
            <div className="col-md-1 col-sm-12 col-lg-1 col-xs-12 col-xl-1 "></div>
            <div className="col-md-3 col-sm-12 col-lg-3 col-xs-12 col-xl-3" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly' }}>
              <div>
                <p className="step_text">Fill the form</p>
                <p className="step_sub_text">Fill the form and message us your requirements.</p>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  );
}

export default Dissertation_service;
