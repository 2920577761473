import React from 'react'
import programming_banner from '../../../assets/img/newHome/programming_banner.png'

function Programming_service() {
  return (
    <>
      <div className="essay_banner">
        <div className="container">
          <div className="row ">
            <div className="col-md-8 col-lg-8 col-xs-12 col-sm-12 col-xl-8">
              <p className="header_font" style={{ fontSize: 67 }}>Get the <span style={{ color: '#FEC00F' }}>best programming  </span>Help</p>
              <p className="header_sub_font pb-4">The student pursuing their professional degrees in coding require programming help to create the coding program. Various companies like Assignment Service Australia have technical experts that facilitate catering different needs of students. ASA helps in providing the best programming assignment at affordable prices. The cheap programming help is the major reason why our services are considered to be highly efficient among various programming learners. </p>
              <a href="/contact" className="get_started ">Contact Us&nbsp;&nbsp;
                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="14" viewBox="0 0 17 14" fill="none">
                  <path d="M16.7923 7.49522L10.4175 13.7949C10.2846 13.9262 10.1043 14 9.91634 14C9.72838 14 9.54812 13.9262 9.41521 13.7949C9.2823 13.6635 9.20764 13.4854 9.20764 13.2996C9.20764 13.1139 9.2823 12.9358 9.41521 12.8044L14.5814 7.69996H0.70831C0.520455 7.69996 0.340293 7.62621 0.207459 7.49495C0.0746255 7.36368 0 7.18564 0 7C0 6.81436 0.0746255 6.63632 0.207459 6.50505C0.340293 6.37378 0.520455 6.30004 0.70831 6.30004H14.5814L9.41521 1.19557C9.2823 1.06423 9.20764 0.886095 9.20764 0.70035C9.20764 0.514606 9.2823 0.336469 9.41521 0.205128C9.54812 0.0737866 9.72838 0 9.91634 0C10.1043 0 10.2846 0.0737866 10.4175 0.205128L16.7923 6.50478C16.8581 6.56978 16.9104 6.64698 16.946 6.73196C16.9817 6.81693 17 6.90801 17 7C17 7.09198 16.9817 7.18307 16.946 7.26804C16.9104 7.35302 16.8581 7.43021 16.7923 7.49522Z" fill="black" />
                </svg></a>
            </div>
            <div className="col-md-4 col-lg-4 col-xs-12 col-sm-12 col-xl-4">
              <img src={programming_banner} className='about_banner_img' />
            </div>
          </div>
        </div>
      </div>

      <div className="bg-black">
        <div className="row">
          <div className="col-md-3 col-sm-12 col-lg-3 col-xl-3 col-xs-12">
            <p className="number">12,958</p>
            <p className="request_text">Student requests</p>
          </div>
          <div className="col-md-3 col-sm-12 col-lg-3 col-xl-3 col-xs-12">
            <p className="number">2,468</p>
            <p className="request_text">Assignments completed</p>
          </div>
          <div className="col-md-3 col-sm-12 col-lg-3 col-xl-3 col-xs-12">
            <p className="number">2,378</p>
            <p className="request_text">Technical projects</p>
          </div>
          <div className="col-md-3 col-sm-12 col-lg-3 col-xl-3 col-xs-12">
            <p className="number">1,273</p>
            <p className="request_text">In house professionals</p>
          </div>
        </div>
      </div>

      <div className='container mt-4'>
        <p className="heading_reason text-center">Languages covered</p>
        <p className="header_sub_font">Our ASA experts aim to offerthe best programming help for different programming assignments. Just name it; you will get it on time. The various languages covered in our programming assignment service mainly include:</p>

        <ul>
          <li><p className="header_sub_font">C/C++ programming assignment service</p></li>
          <li><p className="header_sub_font">Java programming assignment service</p></li>
          <li><p className="header_sub_font">MATLAB programming assignment service</p></li>
          <li><p className="header_sub_font">MYOB programming assignment service</p></li>
          <li><p className="header_sub_font">Data mining programming assignment service</p></li>
          <li><p className="header_sub_font">R programming assignment service</p></li>
          <li><p className="header_sub_font">SAS programming assignment service</p></li>
          <li><p className="header_sub_font">UML programming assignment service</p></li>
          <li><p className="header_sub_font">Database programming assignment service</p></li>
          <li><p className="header_sub_font">Data flow programming assignment service</p></li>
          <li><p className="header_sub_font">Data structure programming assignment service</p></li>
        </ul>
        <p className="header_sub_font">Get in touch with us and get the best grades by getting help from our programming assignment services. </p>

        <p className="heading_reason ">Assistance areas for my Programming Assignment </p>

        <p className="header_sub_font">The various areas from which I can get help from online professionals for my programming assignments for both paid/free services include website, android and IOS applications. Assignmentserviceaustralia.com provides programming help for designing and programming to fulfill the desired purpose. Connect with our experts when you think you need any help with your programming assignment. The experts help in providing with an advanced solution to any programming language problem. When you are looking for any assistance for the development of any Android application. Our programming experts are here to help you create an effective programming assignment for you with 24x7 availability.
          <br></br><br></br>  IOS is an operating system by Apple Inc. for the usage of various Apple products. There are times when the programming assignments may demand various programs specifically for the IOS platform. Development of such programs is considered to be a complex task for students so that emphasise is on getting help with the best programming services. Our programming experts facilitate in resolving all issues and solve all queries appropriately.
        </p>

      </div>

      <div className="yellow_bg">
        <p className="heading_reason text-center pt-4">Trust ASA to get programming help </p>
        <p className="header_sub_font text-center">ASA offers you several safety and security features while seeking assistance from Assignment Service Australia. </p>

        <div className="row " style={{ padding: 50 }} >
          <div className="col-md-4 col-xl-4 col-sm-12 col-lg-4 col-xs-12 ">
            <div className="fields_div" style={{ height: 500 }}>
              <p className="field_block_heading">Timely Submissions</p>
              <p className="header_sub_font">We understand the importance of submitting the assignments on time without any delays. Our experts complete the assignments in a structured manner before time without affecting the quality of work that is to be done. After that, it goes under the quality check and is ready to be submitted on time.</p>
            </div>
          </div>
          <div className="col-md-4 col-xl-4 col-sm-12 col-lg-4 col-xs-12">
            <div className="fields_div" style={{ height: 500 }}>
              <p className="field_block_heading">Originality Report</p>
              <p className="header_sub_font">Our experts ensure to provide the best quality services to the students with minimal plagiarism. We thoroughly check the quality of the work done through authenticated software with evidence that is provided to the client.</p>
            </div>
          </div>
          <div className="col-md-4 col-xl-4 col-sm-12 col-lg-4 col-xs-12">
            <div className="fields_div" style={{ height: 500 }}>
              <p className="field_block_heading">Personalized Approach</p>
              <p className="header_sub_font">We personalize the work according to the client’s requirements which makes the client’s experience smooth. We use a unique approach to work out things in an efficient manner. In simple words, our work depends typically on the needs of the clients.</p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Programming_service
