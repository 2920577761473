import React from "react";
import { LuNetwork } from 'react-icons/lu';
import { MdWeb } from 'react-icons/md';
import { GiRotaryPhone } from 'react-icons/gi';
import { SiXdadevelopers } from 'react-icons/si';
import { BsCodeSlash } from 'react-icons/bs';
import { TbWashMachine } from 'react-icons/tb';
import { CgDatabase } from 'react-icons/cg';
import { AiOutlineFundProjectionScreen } from 'react-icons/ai';
import { AiFillCarryOut } from 'react-icons/ai';
import { GiSkills } from 'react-icons/gi';
import { FaUserNurse } from 'react-icons/fa';
import { GiHumanPyramid } from 'react-icons/gi';
import { Link } from "react-router-dom";
import './Samples.css';

export default function Samples() {


  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };




  return (
    <>
      {/* <!-- Header Start --> */}
      <div class="container-fluid bg-primary mb-5">
        <div
          class="d-flex flex-column align-items-center justify-content-center"
          style={{ minHeight: "400px" }}
        >
          <h3 class="display-3 font-weight-bold text-white text-center">
            Assignment help and sample's review
          </h3>
          <div class="d-inline-flex text-white">
            <p class="m-0">
              <Link class="text-white" to="/">
                Home
              </Link>
            </p>
            <p class="m-0 px-2">/</p>
            <p class="m-0">Sample's</p>
          </div>
        </div>
      </div>
      {/* <!-- Header End --> */}

      <section class="feature-area section-gap" id="service">
        <div class="container">
          <div class="row d-flex justify-content-center">
            <div class="col-md-10 pb-40 text-center">
              <h2>
                Assignment Service Australia showcase best Assignment samples!
              </h2>
              <strong>
                <p class="section-title px-5"><span class="px-2">Here we showcase our samples and assignment help</span></p>
              </strong>
            </div>
          </div>
          <div class="container-fluid pt-3">
            <div class="container pb-3">
              <div class="row mt-5">
                <div class="col-lg-4 col-md-6 pb-1">
                  <Link
                    class="d-flex bg-light shadow-sm border-top rounded mb-4"
                    style={{ padding: "30px", textDecoration: "none" }}>

                    <i
                      class="flaticon-050-fence h1 font-weight-normal text-primary mb-3"
                    ></i>
                    <div class="pl-3">
                      <h5> <span className='dept'> <LuNetwork /></span> Networking</h5>
                      <p class="m-0" style={{ height: "190px", textAlign: "justify" }}>

                        Do you have doubts regarding your networking assignments? Get online help for your networking assignment by reading these recently asked questions by networking students.

                      </p>
                    </div>
                  </Link>
                </div>
                <div class="col-lg-4 col-md-6 pb-1">
                  <Link
                    class="d-flex bg-light shadow-sm border-top rounded mb-4"
                    style={{ padding: "30px", textDecoration: "none" }} >
                    <i
                      class="flaticon-050-fence h1 font-weight-normal text-primary mb-3"
                    ></i>
                    <div class="pl-3">
                      <h6><span className='dept'> <MdWeb /></span> Web Design & Development</h6>
                      <p class="m-0" style={{ height: "190px", textAlign: "justify" }}>

                        Web designing and development is a term used to describe the process of making a website. It
                        involves many different skills and disciplines to develop various areas of a website.

                      </p>
                    </div>
                  </Link>
                </div>
                <div class="col-lg-4 col-md-6 pb-1">
                  <Link
                    class="d-flex bg-light shadow-sm border-top rounded mb-4"
                    style={{ padding: "30px", textDecoration: "none" }} >
                    <i
                      class="flaticon-050-fence h1 font-weight-normal text-primary mb-3"
                    ></i>
                    <div class="pl-3">
                      <h5><span className='dept'> <GiRotaryPhone /></span> Information System</h5>
                      <p class="m-0" style={{ height: "190px", textAlign: "justify" }}>
                        Are you stuck with your information system assignment? We have answered some recently but frequently asked questions by students to help you in completing your information system assignment online.
                      </p>
                    </div>
                  </Link>
                </div>
                <div class="col-lg-4 col-md-6 pb-1">
                  <Link
                    class="d-flex bg-light shadow-sm border-top rounded mb-4"
                    style={{ padding: "30px", textDecoration: "none" }} >
                    <i
                      class="flaticon-050-fence h1 font-weight-normal text-primary mb-3"
                    ></i>
                    <div class="pl-3">
                      <h5><span className='dept'> <SiXdadevelopers /></span> Research & Development</h5>
                      <p class="m-0" style={{ height: "190px", textAlign: "justify" }}>
                        Research and Development popularly known as R&amp;D, are activities that organisations hold to
                        produce new products and bring innovations into the world. R&amp;D activities operate in long term and
                        are among key assets of corporates.
                      </p>
                    </div>
                  </Link>
                </div>
                <div class="col-lg-4 col-md-6 pb-1">
                  <Link
                    class="d-flex bg-light shadow-sm border-top rounded mb-4"
                    style={{ padding: "30px", textDecoration: "none" }} >
                    <i
                      class="flaticon-050-fence h1 font-weight-normal text-primary mb-3"
                    ></i>
                    <div class="pl-3">
                      <h5><span className='dept'> <BsCodeSlash /></span> Programming</h5>
                      <p class="m-0" style={{ height: "190px", textAlign: "justify" }}>
                        Programming is a process to create a set of instructions that are made such a way through which a
                        computer performs a certain task. Programmers write program code in a variety of computer
                        languages to create applications or software.
                      </p>
                    </div>
                  </Link>
                </div>
                <div class="col-lg-4 col-md-6 pb-1">
                  <Link
                    class="d-flex bg-light shadow-sm border-top rounded mb-4"
                    style={{ padding: "30px", textDecoration: "none" }} >
                    <i
                      class="flaticon-050-fence h1 font-weight-normal text-primary mb-3"
                    ></i>
                    <div class="pl-3">

                      <h5><span className='dept'> <TbWashMachine /></span> Machine Learning</h5>
                      <p class="m-0" style={{ height: "190px", textAlign: "justify" }}>
                        You might encounter different questions in your mind while working with your Machine Learning Assignment. Ask us your questions. We will provide you the best Online Machine Learning Assignment Help.
                      </p>
                    </div>
                  </Link>
                </div>
                <div class="col-lg-4 col-md-6 pb-1">
                  <Link
                    class="d-flex bg-light shadow-sm border-top rounded mb-4"
                    style={{ padding: "30px", textDecoration: "none" }} >
                    <i
                      class="flaticon-050-fence h1 font-weight-normal text-primary mb-3"
                    ></i>
                    <div class="pl-3">
                      <h5><span className='dept'> <CgDatabase /></span> Database</h5>
                      <p class="m-0" style={{ height: "190px", textAlign: "justify" }}>
                        Questions related to database assignment, asked by students are answered by quickassignment.co.uk. Quickassignment is the best company to help you with database assignment services online.
                      </p>
                    </div>
                  </Link>
                </div>
                <div class="col-lg-4 col-md-6 pb-1">
                  <Link
                    class="d-flex bg-light shadow-sm border-top rounded mb-4"
                    style={{ padding: "30px", textDecoration: "none" }} >
                    <i
                      class="flaticon-050-fence h1 font-weight-normal text-primary mb-3"
                    ></i>
                    <div class="pl-3">
                      <h5><span className='dept'> <AiOutlineFundProjectionScreen /></span> Project Management</h5>
                      <p class="m-0" style={{ height: "190px", textAlign: "justify" }}>
                        Project management means application of processes, methods, skills, tools and techniques to
                        deliver something of value. Any project in making goes through various phases, these phase are
                        collectively known as project management.
                      </p>
                    </div>
                  </Link>
                </div>
                <div class="col-lg-4 col-md-6 pb-1">
                  <Link
                    class="d-flex bg-light shadow-sm border-top rounded mb-4"
                    style={{ padding: "30px", textDecoration: "none" }} >
                    <i
                      class="flaticon-050-fence h1 font-weight-normal text-primary mb-3"
                    ></i>
                    <div class="pl-3">
                      <h5><span className='dept'> <AiFillCarryOut /></span> Management</h5>

                      <p class="m-0" style={{ height: "190px", textAlign: "justify" }}>

                        Management refers to the administration team of an organisation. It is an act to bring people with
                        ambition together to complete the desired goal set up by an organisation. The management of an
                        organisation or business plans, organises and leads its employees towards goals.
                      </p>
                    </div>
                  </Link>
                </div>
                <div class="col-lg-4 col-md-6 pb-1">
                  <Link
                    class="d-flex bg-light shadow-sm border-top rounded mb-4"
                    style={{ padding: "30px", textDecoration: "none" }} >
                    <i
                      class="flaticon-050-fence h1 font-weight-normal text-primary mb-3"
                    ></i>
                    <div class="pl-3">
                      <h5><span className='dept'> <GiSkills /></span> Proffesional Skills</h5>
                      <p class="m-0" style={{ height: "190px", textAlign: "justify" }}>
                        Professional skills are the skills that are specific to some career competencies. These skills are not
                        taught as a part of academic courses. These are the skills that employers look for in the employees while
                        they hire them.
                      </p>
                    </div>
                  </Link>
                </div>
                <div class="col-lg-4 col-md-6 pb-1">
                  <Link
                    class="d-flex bg-light shadow-sm border-top rounded mb-4"
                    style={{ padding: "30px", textDecoration: "none" }} >
                    <i
                      class="flaticon-050-fence h1 font-weight-normal text-primary mb-3"
                    ></i>
                    <div class="pl-3">
                      <h5><span className='dept'> <FaUserNurse /></span> Nursing</h5>
                      <p class="m-0" style={{ height: "190px", textAlign: "justify" }}>
                        Nursing is profession that deals with active care of physical, mental, emotional and social state of a
                        person. Nursing is an important organ of a health care system as nurses can actively monitor the
                        health status of individual.
                      </p>
                    </div>
                  </Link>
                </div>
                <div class="col-lg-4 col-md-6 pb-1">
                  <Link
                    class="d-flex bg-light shadow-sm border-top rounded mb-4"
                    style={{ padding: "30px", textDecoration: "none" }} >
                    <i
                      class="flaticon-050-fence h1 font-weight-normal text-primary mb-3"
                    ></i>
                    <div class="pl-3">
                      <h5><span className='dept'> <GiHumanPyramid /></span> Human Resources</h5>
                      <p class="m-0" style={{ height: "190px", textAlign: "justify" }}>
                        Human resources is the business division who is responsible for recruiting and training the new applicants for job. It also helps the organisations in dealing with the business environment change.
                      </p>
                    </div>
                  </Link>
                </div>

              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  );
}
