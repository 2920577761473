export const BaseUrlApi = "https://admin.assignmentserviceaustralia.com/api/"
export const BaseUrlApi2 = "https://admin.assignmentserviceaustralia.com/"
// export const BaseUrlApi = "http://localhost/asa_backend/api/"
// export const BaseUrlApi2 = "http://localhost/asa_backend/"

export const ContactUrl = BaseUrlApi + 'contact'

export const AssignmentUrl = BaseUrlApi + 'assignment'

export const CountryUrl = BaseUrlApi + 'country'

export const ColorUrl = BaseUrlApi + 'color_get'

export const TestimonialUrl = BaseUrlApi + 'testimonial'

export const ImageUrl = BaseUrlApi2 + '/public/images/'

export const BaseUrl = "http://localhost:3000/"
// export const BaseUrl = "https://assignmentserviceaustralia.com/"

