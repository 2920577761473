import React, { Component } from 'react';
import './Reviews.css'

import accounting from '../../assets/img/newHome/accounting.png'
import business_mgnt from '../../assets/img/newHome/business_mgnt.png'
import economics from '../../assets/img/newHome/economics.png'
import english_litreture from '../../assets/img/newHome/english_litreture.png'
import finance from '../../assets/img/newHome/finance.png'
import helthcare from '../../assets/img/newHome/helthcare.png'
import hospitality from '../../assets/img/newHome/hospitality.png'
import it from '../../assets/img/newHome/it.png'
import marketing from '../../assets/img/newHome/marketing.png'
import networking_expert from '../../assets/img/newHome/networking_expert.png'
import nursing from '../../assets/img/newHome/nursing.png'
import tourism from '../../assets/img/newHome/tourism.png'
import map from '../../assets/img/newHome/client_map.png'
import best_in_ass from '../../assets/img/newHome/best_in_ass.png'
import "owl.carousel/dist/assets/owl.carousel.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import OwlCarousel from 'react-owl-carousel2';
import * as Constant from '../Constant'

const options = {
  items: 2,
  autoplay: true,
  loop: true
};

export default class Reviews extends Component {
  constructor() {
    super();
    this.state = {
      testimonial: [],
    }
  }

  getTestimonial = async () => {
    await fetch(Constant.TestimonialUrl, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success === true) {
          this.setState({
            testimonial: data.data
          });
        }
      })
      .catch((error) => {
        if (error.message === "Failed to fetch") {
        } else {
          console.error("Error:!!!!", error);
        }
      });
  };

  componentDidMount = () => {
    // this.getColor();
    this.getTestimonial();
  };

  render() {
    return (
      <>
        <div>
          <p className="grades mt-5">Our<span style={{ color: '#FEC00F' }}>&nbsp;Happy Clients</span></p>
          <p className="experience">We have more than 4000 happy clients across the globe.</p>
          <div className="row mt-5">
            <div className="col-md-5 col-sm-12 col-lg-5 col-xl-5 col-xs-12 ">
              <img src={map} alt="map" />
            </div>
            <div className="col-md-7 col-sm-12 col-lg-7 col-xl-7 col-xs-12 ">
              <OwlCarousel options={options}>
                {
                  this.state.testimonial.map((item) =>
                  (
                    <div className="row">
                      <div className="col-md-12">
                        <div class="testimonial-item">
                          <div class="rounded">
                            <img
                              class="test_image"
                              src={Constant.ImageUrl + item.image}
                              alt="Testimonial"
                              style={{ width: '340px', border: '2px solid #000' }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ),
                  )
                }
              </OwlCarousel>
            </div>
          </div>
        </div>

        <div className='pb-5'>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <p className="grades mt-5">Get in touch with<span style={{ color: '#FEC00F' }}>&nbsp;an expert</span>&nbsp;now!</p>
            <a className="get_started" href='/contact' style={{ height: 50, marginTop: 35, marginLeft: 30, paddingTop: 14 }}>Contact Us&nbsp;&nbsp;
              <svg xmlns="http://www.w3.org/2000/svg" width="17" height="14" viewBox="0 0 17 14" fill="none">
                <path d="M16.7923 7.49522L10.4175 13.7949C10.2846 13.9262 10.1043 14 9.91634 14C9.72838 14 9.54812 13.9262 9.41521 13.7949C9.2823 13.6635 9.20764 13.4854 9.20764 13.2996C9.20764 13.1139 9.2823 12.9358 9.41521 12.8044L14.5814 7.69996H0.70831C0.520455 7.69996 0.340293 7.62621 0.207459 7.49495C0.0746255 7.36368 0 7.18564 0 7C0 6.81436 0.0746255 6.63632 0.207459 6.50505C0.340293 6.37378 0.520455 6.30004 0.70831 6.30004H14.5814L9.41521 1.19557C9.2823 1.06423 9.20764 0.886095 9.20764 0.70035C9.20764 0.514606 9.2823 0.336469 9.41521 0.205128C9.54812 0.0737866 9.72838 0 9.91634 0C10.1043 0 10.2846 0.0737866 10.4175 0.205128L16.7923 6.50478C16.8581 6.56978 16.9104 6.64698 16.946 6.73196C16.9817 6.81693 17 6.90801 17 7C17 7.09198 16.9817 7.18307 16.946 7.26804C16.9104 7.35302 16.8581 7.43021 16.7923 7.49522Z" fill="black" />
              </svg>
            </a>
          </div>
          <hr style={{ backgroundColor: '#000', width: '78%' }}></hr>
          <p className="grades mt-4">We serve<span style={{ color: '#FEC00F' }}>&nbsp;best in Assignment</span>&nbsp;help!</p>

          <div className='row'>
            <img src={best_in_ass} style={{ width: '100%' }} />
          </div>
        </div>

        <div className="expert_bg">
          <p className="grades pt-5 " style={{ color: '#000' }}>Get <span style={{ color: '#FEC00F' }}>the experts</span> you need</p>
          <p className="experience pb-3" style={{ color: '#000' }}>We have highly educated and qualified writers to help students.</p>
          <div className="container">
            <div className="row">
              <div className="col-md-3 col-sm-12 col-lg-3 col-xl-3 col-xs-12 p-5 text-center">
                <img src={accounting} className="mx-auto" alt="accounting" />
                <p className="expert_text">Accounting</p>
              </div>
              <div className="col-md-3 col-sm-12 col-lg-3 col-xl-3 col-xs-12 p-5 text-center">
                <img src={finance} className="mx-auto" alt="finance" />
                <p className="expert_text">Finance</p>
              </div>
              <div className="col-md-3 col-sm-12 col-lg-3 col-xl-3 col-xs-12 p-5 text-center">
                <img src={it} className="mx-auto" alt="it" />
                <p className="expert_text">IT</p>
              </div>
              <div className="col-md-3 col-sm-12 col-lg-3 col-xl-3 col-xs-12 p-5 text-center">
                <img src={english_litreture} className="mx-auto" alt="English Literature" />
                <p className="expert_text">English<br></br>
                  Literature</p>
              </div>
              <div className="col-md-3 col-sm-12 col-lg-3 col-xl-3 col-xs-12 p-5 text-center">
                <img src={business_mgnt} className="mx-auto" alt="business_mgnt" />
                <p className="expert_text">Business
                  Management</p>
              </div>
              <div className="col-md-3 col-sm-12 col-lg-3 col-xl-3 col-xs-12 p-5 text-center">
                <img src={marketing} className="mx-auto" alt="marketing" />
                <p className="expert_text">Marketing</p>
              </div>
              <div className="col-md-3 col-sm-12 col-lg-3 col-xl-3 col-xs-12 p-5 text-center">
                <img src={nursing} className="mx-auto" alt="nursing" />
                <p className="expert_text">Nursing</p>
              </div>
              <div className="col-md-3 col-sm-12 col-lg-3 col-xl-3 col-xs-12 p-5 text-center">
                <img src={economics} className="mx-auto" alt="economics" />
                <p className="expert_text">Economics</p>
              </div>
              <div className="col-md-3 col-sm-12 col-lg-3 col-xl-3 col-xs-12 p-5 text-center">
                <img src={helthcare} className="mx-auto" alt="helthcare" />
                <p className="expert_text" style={{ marginTop: 31 }}>Health care</p>
              </div>
              <div className="col-md-3 col-sm-12 col-lg-3 col-xl-3 col-xs-12 p-5 text-center">
                <img src={networking_expert} className="mx-auto" alt="networking_expert" />
                <p className="expert_text">Networking</p>
              </div>
              <div className="col-md-3 col-sm-12 col-lg-3 col-xl-3 col-xs-12 p-5 text-center">
                <img src={tourism} className="mx-auto" alt="tourism" />
                <p className="expert_text">Tourism</p>
              </div>
              <div className="col-md-3 col-sm-12 col-lg-3 col-xl-3 col-xs-12 p-5 text-center">
                <img src={hospitality} className="mx-auto" alt="hospitality" />
                <p className="expert_text">Hospitality</p>
              </div>
            </div>
          </div>
        </div>

      </>
    )
  }
}
