import React, { Component } from "react";
import { Link } from "react-router-dom";
import "owl.carousel/dist/assets/owl.carousel.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import OwlCarousel from 'react-owl-carousel2';

import TawkMessengerReact from "@tawk.to/tawk-messenger-react";
import "./Index.css";
import * as Constant from "../Constant";
import "react-phone-input-2/lib/style.css";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import best_help from '../../assets/img/newHome/best_help.webp'
import easy_help from '../../assets/img/newHome/easy_help.webp'
import program_help from '../../assets/img/newHome/program_help.webp'
import proof_help from '../../assets/img/newHome/proof_help.webp'
import research_help from '../../assets/img/newHome/research_help.webp'
import networking from '../../assets/img/newHome/networking.webp'
import cpu from '../../assets/img/newHome/cpu.webp'
import medal from '../../assets/img/newHome/medal.webp'
import modal from '../../assets/img/newHome/modal.webp'
import map from '../../assets/img/newHome/client_map.webp'
import provide_img from '../../assets/img/newHome/provide_img.webp'
import step from '../../assets/img/newHome/step.webp'
import banner_img from '../../assets/img/newHome/banner_img.webp'
import LoadingOverlay from "react-loading-overlay";

const options = {
  items: 2,
  autoplay: true,
  loop: true
};


export class Index extends Component {
  constructor() {
    super();
    this.state = {
      fullName: "",
      email: "",
      resume: "",
      requirement: "",
      phone: "",
      attachment: "",

      testimonial: [],
      fullNameError: null,
      emailError: null,
      numberError: null,
      requirementError: null,
      attachmentError: null,
      isLoading: false,
      Loading: true,

    };
  }

  validate = () => {
    let fullNameError = "";
    let emailError = "";
    let numberError = "";
    let requirementError = "";
    let attachmentError = "";

    if (!this.state.fullName) {
      fullNameError = "Please enter full name.";
    }
    var reg = new RegExp("[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,64}");
    if (!this.state.email) {
      emailError = "Please enter email.";
    } else {
      if (!reg.test(this.state.email)) {
        emailError = "Please enter a valid email address.";
      }
    }
    if (!this.state.phone) {
      numberError = "Please enter number.";
    }
    if (!this.state.requirement) {
      requirementError = "Please enter your requirement.";
    }
    if (!this.state.attachment) {
      attachmentError = "Please attach file.";
    }

    if (
      fullNameError ||
      emailError ||
      numberError ||
      requirementError ||
      attachmentError
    ) {
      this.setState({
        fullNameError,
        emailError,
        numberError,
        requirementError,
        attachmentError
      });
      return false;
    }
    return true;
  };

  notify = () => {
    console.log('okkkkk')
    toast.success('Your request forwarded successfully..', {
      position: 'top-center',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  onSubmit = () => {

    if (this.validate()) {
      this.setState({
        fullNameError: null,
        emailError: null,
        numberError: null,
        requirementError: null,
        attachmentError: null
      });
      this.onContact();
    }
  };

  onContact = async () => {
    this.setState({ isLoading: true });

    const formData = new FormData();
    formData.append("fullname", this.state.fullName);
    formData.append("email", this.state.email);
    formData.append("number", this.state.phone);
    formData.append("requirement", this.state.requirement);
    formData.append("attachment", this.state.attachment);

    await fetch(Constant.AssignmentUrl, {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success === true) {
          this.setState({
            isLoading: false,
            fullName: "",
            email: "",
            phone: "",
            requirement: ""
          });
          this.notify();
          document.getElementById('attachment').value = '';

          this.resetFile();
        } else {
          this.setState({ isLoading: false });
        }
      })
      .catch((error) => {
        if (error.message === "Failed to fetch") {
        } else {
          console.error("Error:!!!!", error);
        }
        this.setState({ isLoading: false });
      });
  };

  getTestimonial = async () => {
    await fetch(Constant.TestimonialUrl, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success === true) {
          this.setState({
            testimonial: data.data
          });
        }
      })
      .catch((error) => {
        if (error.message === "Failed to fetch") {
        } else {
          console.error("Error:!!!!", error);
        }
      });
  };

  handleChangePhone = event => {
    const result = event.target.value.replace(/[^0-9]/gi, '');
    this.setState({ phone: result })
  };

  resetFile() {
    const file = document.querySelector(".file");
    file.value = "";
  }

  componentDidMount = () => {
    // this.getColor();
    this.getTestimonial();
  };

  goToTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
  handleChangeFirstName = event => {
    const result = event.target.value.replace(/[^a-zA-Z, ]/, '');
    this.setState({
      fullName: result,
    })
  };
  render() {

    return (
      <>
        <LoadingOverlay
          active={this.state.isLoading}
          spinner
          fadeSpeed={10}
          text="Message Sending..."
        >

          <div className="home_banner">
            <div className="row " style={{ paddingLeft: '18%', paddingRight: '18%' }}>
              <div className="col-md-7 col-lg-7 col-xs-12 col-sm-12 col-xl-7" >
                <img src={banner_img} className="banner_img" />
              </div>
              <div className="col-md-5 col-lg-5 col-xs-12 col-sm-12 col-xl-5" style={{ marginBottom: 30 }}>
                <div className="black_bg p-4">
                  <p className="form_heading" style={{ color: '#fff' }}>Assignment Help Request</p>
                  <div className="row ">
                    <div className="col-md-12 col-lg-12 col-sm-12">

                      <div className="form-group">
                        <input type="text" className="form-control field" value={this.state.fullName} onChange={(e) => this.handleChangeFirstName(e)} placeholder="Name" />
                        {!!this.state.fullNameError && (
                          <text style={{ color: 'red' }}>
                            {this.state.fullNameError}
                          </text>
                        )}
                      </div>

                      <div className="form-group">
                        <input type="text" className="form-control field" value={this.state.email} onChange={(e) => this.setState({ email: e.target.value })} placeholder="Email" />
                        {!!this.state.emailError && (
                          <text style={{ color: 'red' }}>
                            {this.state.emailError}
                          </text>
                        )}
                      </div>

                      <div className="form-group">
                        <input type="text" className="form-control field" maxLength={11} value={this.state.phone} onChange={(e) => this.handleChangePhone(e)} placeholder="Phone No." />
                        {!!this.state.numberError && (
                          <text style={{ color: 'red' }}>
                            {this.state.numberError}
                          </text>
                        )}
                      </div>

                      <div className="form-group">
                        <input type="text" className="form-control field" value={this.state.requirement} onChange={(e) => this.setState({ requirement: e.target.value })} placeholder="Write Your Requirement" />
                        {!!this.state.requirementError && (
                          <text style={{ color: 'red' }}>
                            {this.state.requirementError}
                          </text>
                        )}
                      </div>

                      <div className="form-group">
                        <input type="file" className="form-control field" id="attachment" onChange={(event) => this.setState({
                          attachment: event.target.files[0]
                        })} style={{ paddingTop: 9 }} />
                        {!!this.state.attachmentError && (
                          <text style={{ color: "red", marginLeft: "10px" }}>
                            {this.state.attachmentError}
                          </text>
                        )}
                      </div>

                      <div className="form-group" style={{ marginTop: 30, display: 'flex', justifyContent: 'flex-end' }}>
                        <a className="get_started" onClick={this.onSubmit}>Send Message
                        </a>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer />

          <div className="bg-yellow">
            <div className="container">
              <div className="row">
                <p className="header_font" style={{ fontSize: 29 }}>Assignment Service Australia Provides plagiarism free assignment help with assured  results</p>
                <p className="header_sub_font">Assignment Service Australia is the perfect place where students can get highly reliable dissertation services. We provide best assignment help in Australia with assured results. We emphasise following the proper format that enables students to receive better grades. It is necessary to consistently maintain their performance level throughout the complete year. Due to the rising burden of completing other assignments, most of the students struggle with selection of an appropriate topic for their dissertation, nursing assignments. During this time, ASA dissertation services facilitate the most effective topic.</p>
              </div>
            </div>
          </div>

          <div className="bg-black">
            <div className="row">
              <div className="col-md-3 col-sm-12 col-lg-3 col-xl-3 col-xs-12">
                <p className="number">12,958</p>
                <p className="request_text">Student requests</p>
              </div>
              <div className="col-md-3 col-sm-12 col-lg-3 col-xl-3 col-xs-12">
                <p className="number">2,468</p>
                <p className="request_text">Assignments completed</p>
              </div>
              <div className="col-md-3 col-sm-12 col-lg-3 col-xl-3 col-xs-12">
                <p className="number">2,378</p>
                <p className="request_text">Technical projects</p>
              </div>
              <div className="col-md-3 col-sm-12 col-lg-3 col-xl-3 col-xs-12">
                <p className="number">1,273</p>
                <p className="request_text">In house professionals</p>
              </div>
            </div>
          </div>

          {/* <!-- Facilities Start --> */}
          <div className="grades_banner ">
            <p className="grades mt-5">Get the<span style={{ color: '#FEC00F' }}>&nbsp;highest grades</span></p>
            <p className="experience">We have 15+ years of experience in academic writing and available round the clock</p>
            <div className="container">
              <div className="row ">
                <div className="col-lg-4 col-md-6">
                  <Link
                    className="d-flex bg-light  rounded mb-4 grade_block"
                    onClick={this.goToTop}
                    to="/Dissertation"
                  >
                    <div className="pl-4">
                      <img src={best_help} alt="best_help" />
                      <h4 >Best Dissertation Help</h4>
                      <p className="m-0" >
                        Dissertation is considered to be the most effective component of several courses. The dissertation covers the submission of an efficient piece of writing.
                      </p>
                    </div>
                  </Link>
                </div>
                <div className="col-lg-4 col-md-6 ">
                  <Link
                    className="d-flex bg-light  rounded mb-4 grade_block"
                    onClick={this.goToTop}
                    to="/essay"
                  >
                    <div className="pl-4">
                      <img src={easy_help} alt="easy_help" />
                      <h4>Essay Writing Help</h4>
                      <p className="m-0" >
                        Being an essay service provider, Assignmentserviceaustralia.com would not only benefit students in the achievement of enhanced excellence by providing quality writing but also enable them to provide valuable knowledge.
                      </p>
                    </div>
                  </Link>
                </div>
                <div className="col-lg-4 col-md-6 ">
                  <Link
                    className="d-flex bg-light rounded mb-4 grade_block"
                    onClick={this.goToTop}
                    to="/Research"
                  >
                    <div className="pl-4">
                      <img src={research_help} alt="research_help" />
                      <h4>Research Paper Help </h4>
                      <p className="m-0" >
                        We have an array of experts to provide best research paper
                        help in any discipline through our website.
                      </p>
                    </div>
                  </Link>
                </div>
                <div className="col-lg-4 col-md-6 ">
                  <Link
                    className="d-flex bg-light rounded mb-4 grade_block"
                    onClick={this.goToTop}
                    to="/Programming"
                  >
                    <div className="pl-4">
                      <img src={program_help} alt="program_help" />
                      <h4>Programming Help</h4>
                      <p className="m-0" >
                        Our team is here with the exceptional support for your IT
                        projects, Computer Science and Networking Assignment help
                      </p>
                    </div>
                  </Link>
                </div>
                <div className="col-lg-4 col-md-6 ">
                  <Link
                    className="d-flex bg-light rounded mb-4 grade_block"
                    onClick={this.goToTop}
                    to="/Proofreading"
                  >
                    <div className="pl-4">
                      <img src={proof_help} alt="proof_help" />
                      <h4>Proof Reading Help</h4>
                      <p className="m-0" >
                        Don’t let commas, run on sentences or misspellings ruin
                        overall grades of your assignment.
                      </p>
                    </div>
                  </Link>
                </div>
                <div className="col-lg-4 col-md-6 ">
                  <Link
                    className="d-flex bg-light rounded mb-4 grade_block"
                    onClick={this.goToTop}
                    to="/Networking"
                  >
                    <div className="pl-4">
                      <img src={networking} alt="networking" />
                      <h4> Networking assignments</h4>
                      <p className="m-0" style={{ height: "90px" }}>
                        Our team of experts are flexible and adaptable to
                        expertise regarding networking assignments and technical projects.
                      </p>
                    </div>
                  </Link>
                </div>
              </div>
            </div>

          </div>
          {/* <!-- Facilities Start --> */}

          <div className="provide_banner">
            <div className="container">
              <div className="row ">
                <div className="col-md-7 col-lg-7 col-xs-12 col-sm-12 col-xl-7 " style={{ paddingTop: 20 }}>
                  <p className="header_font" style={{ fontSize: 58 }}><span style={{ color: '#FEC00F' }}> Best Assignment Service </span>In Australia</p>
                  <p className="we_provide_subtext" style={{ fontSize: 22, textAlign: 'justify' }}>
                    We understand that it’s difficult for students to manage their personal and professional lives while also ensuring that they attain higher grades in class. Due to this, we ensure to provide students with personalized assignment solutions and help so that they can focus on one thing at a time and seek the help of experts to attain higher grades in class. Assignments Service Australia is here to fulfil the needs and expectations of the students and prevent the risk of failing to submit homework assignments on time.
                  </p>
                  <a href="/contact" className="get_started">Contact Us&nbsp;&nbsp;
                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="14" viewBox="0 0 17 14" fill="none">
                      <path d="M16.7923 7.49522L10.4175 13.7949C10.2846 13.9262 10.1043 14 9.91634 14C9.72838 14 9.54812 13.9262 9.41521 13.7949C9.2823 13.6635 9.20764 13.4854 9.20764 13.2996C9.20764 13.1139 9.2823 12.9358 9.41521 12.8044L14.5814 7.69996H0.70831C0.520455 7.69996 0.340293 7.62621 0.207459 7.49495C0.0746255 7.36368 0 7.18564 0 7C0 6.81436 0.0746255 6.63632 0.207459 6.50505C0.340293 6.37378 0.520455 6.30004 0.70831 6.30004H14.5814L9.41521 1.19557C9.2823 1.06423 9.20764 0.886095 9.20764 0.70035C9.20764 0.514606 9.2823 0.336469 9.41521 0.205128C9.54812 0.0737866 9.72838 0 9.91634 0C10.1043 0 10.2846 0.0737866 10.4175 0.205128L16.7923 6.50478C16.8581 6.56978 16.9104 6.64698 16.946 6.73196C16.9817 6.81693 17 6.90801 17 7C17 7.09198 16.9817 7.18307 16.946 7.26804C16.9104 7.35302 16.8581 7.43021 16.7923 7.49522Z" fill="black" />
                    </svg></a>
                </div>
                <div className="col-md-5 col-lg-5 col-xs-12 col-sm-12 col-xl-5">
                  <img src={provide_img} alt="provide_img" className="best_banner_img" />
                </div>
              </div>
            </div>
          </div>

          <div className="bg-black" style={{ padding: 80 }}>
            <div className="row">
              <div className="col-md-4 col-sm-12 col-lg-4 col-xl-4 col-xs-12 analytics_view">
                <img src={medal} alt="medal" />
                <p className="request_text">We offer excellent quality assignment solutions at an affordable price.</p>
              </div>
              <div className="col-md-4 col-sm-12 col-lg-4 col-xl-4 col-xs-12 analytics_view">
                <img src={cpu} alt="cpu" />
                <p className="request_text">We use analytical and critical thinking skills when developing an assignment solution.</p>
              </div>
              <div className="col-md-4 col-sm-12 col-lg-4 col-xl-4 col-xs-12 analytics_view">
                <img src={modal} alt="modal" />
                <p className="request_text">We deliver high-quality academic content for more than 38 subjects.</p>
              </div>
            </div>
          </div>

          <div>
            <p className="grades mt-5">Our<span style={{ color: '#FEC00F' }}>&nbsp;Happy Clients</span></p>
            <p className="experience">We have more than 4000 happy clients across the globe.</p>
            <div className="row mt-5">
              <div className="col-md-6 col-sm-12 col-lg-6 col-xl-6 col-xs-12 ">
                <img src={map} alt="map" className="best_banner_img" />
              </div>
              <div className="col-md-6 col-sm-12 col-lg-6 col-xl-6 col-xs-12 ">
                <OwlCarousel options={options} >
                  {
                    this.state.testimonial.map((item) =>
                    (
                      <div className="row" >
                        <div className="col-md-12 col-sm-12 col-lg-12 col-xl-12 col-xs-12">
                          <div className="testimonial-item" >
                            <div className="rounded ">
                              <img
                                className="test_image"
                                src={Constant.ImageUrl + item.image}
                                alt="Testimonial"

                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    ),
                    )
                  }
                </OwlCarousel>
              </div>
            </div>
          </div>

          <div className="process_sec ">
            <p className="grades pt-5">Easy<span style={{ color: '#fff' }}>&nbsp;3-step</span>Process</p>
            <div className="container">
              <div className="row" style={{ marginTop: 100, paddingBottom: 100 }}>
                <div className="col-md-3 col-sm-12 col-lg-3 col-xs-12 col-xl-3 adjust">
                  <div>
                    <p className="step_text">Contact Us</p>
                    <p className="step_sub_text">Contact us via the button below.</p>
                  </div>
                  <div>
                    <p className="step_text">Get the experts</p>
                    <p className="step_sub_text">Our team will review the form, get a expert for your assignment.</p>
                  </div>
                </div>
                <div className="col-md-1 col-sm-12 col-lg-1 col-xs-12 col-xl-1 "></div>
                <div className="col-md-4 col-sm-12 col-lg-4 col-xs-12 col-xl-4" style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                  <img src={step} alt="step" className="step_img" />
                </div>
                <div className="col-md-1 col-sm-12 col-lg-1 col-xs-12 col-xl-1 "></div>
                <div className="col-md-3 col-sm-12 col-lg-3 col-xs-12 col-xl-3" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly' }}>
                  <div>
                    <p className="step_text">Fill the form</p>
                    <p className="step_sub_text">Fill the form and message us your requirements.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <TawkMessengerReact
            propertyId="5bf1a00379ed6453cca9f820"
            widgetId="default"
          />
        </LoadingOverlay>

      </>
    );
  }
}

export default Index;
