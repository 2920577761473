import React, { Component } from "react";
import * as Constant from "../Constant";
import about_banner from '../../assets/img/newHome/about_banner.png'
import './About.css'
import cpu from '../../assets/img/newHome/cpu.webp'
import medal from '../../assets/img/newHome/medal.webp'
import modal from '../../assets/img/newHome/modal.webp'

export class About extends Component {
  constructor() {
    super();
    this.state = {
      updateDynamicColorPrimary: "",
      updateDynamicColorSecondary: "",
      updateDynamicColorTertiary: "",

    };
  }

  getColor = async () => {
    await fetch(Constant.ColorUrl, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success === true) {
          this.setState({
            Loading: false,
            updateDynamicColorPrimary: data.data.primary,
            updateDynamicColorSecondary: data.data.secondary,
            updateDynamicColorTertiary: data.data.tertiary,

          });
        }
      })
      .catch((error) => {
        if (error.message === "Failed to fetch") {
        } else {
          console.error("Error:!!!!", error);
        }
      });
  };


  updateDynamicColorPrimary = (color) => {
    document.documentElement.style.setProperty('--dynamic-color-primary', color);
  };

  updateDynamicColorSecondary = (color2) => {
    document.documentElement.style.setProperty('--dynamic-color-secondary', color2);
  };

  updateDynamicColorTertiary = (color3) => {
    document.documentElement.style.setProperty('--dynamic-color-tertiary', color3);
  };

  componentDidMount = () => {
    this.getColor();
  };

  render() {
    return (
      <React.Fragment>


        <div className="about_banner">
          <div className="container">
            <div className="row ">
              <div className="col-md-6 col-lg-6 col-xs-12 col-sm-12 col-xl-6">
                <p className="header_font" style={{ fontSize: 61 }}>We Provide <span style={{ color: '#FEC00F' }}>Best Assignment Service </span>In Australia</p>
                <p className="header_sub_font pb-4">We at Assignmentserviceaustralia.com, aim to offer various academic services. It may be case study help, online quiz help, online exam help, writing research papers and homework help. For every service, we assign an expert who has enough knowledge and competencies in a specific area. Experience the writing service without any complaint.</p>
                <a href="/contact" className="get_started ">Contact Us&nbsp;&nbsp;
                  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="14" viewBox="0 0 17 14" fill="none">
                    <path d="M16.7923 7.49522L10.4175 13.7949C10.2846 13.9262 10.1043 14 9.91634 14C9.72838 14 9.54812 13.9262 9.41521 13.7949C9.2823 13.6635 9.20764 13.4854 9.20764 13.2996C9.20764 13.1139 9.2823 12.9358 9.41521 12.8044L14.5814 7.69996H0.70831C0.520455 7.69996 0.340293 7.62621 0.207459 7.49495C0.0746255 7.36368 0 7.18564 0 7C0 6.81436 0.0746255 6.63632 0.207459 6.50505C0.340293 6.37378 0.520455 6.30004 0.70831 6.30004H14.5814L9.41521 1.19557C9.2823 1.06423 9.20764 0.886095 9.20764 0.70035C9.20764 0.514606 9.2823 0.336469 9.41521 0.205128C9.54812 0.0737866 9.72838 0 9.91634 0C10.1043 0 10.2846 0.0737866 10.4175 0.205128L16.7923 6.50478C16.8581 6.56978 16.9104 6.64698 16.946 6.73196C16.9817 6.81693 17 6.90801 17 7C17 7.09198 16.9817 7.18307 16.946 7.26804C16.9104 7.35302 16.8581 7.43021 16.7923 7.49522Z" fill="black" />
                  </svg></a>
              </div>
              <div className="col-md-1 col-lg-1 col-xs-12 col-sm-12 col-xl-1"></div>
              <div className="col-md-5 col-lg-5 col-xs-12 col-sm-12 col-xl-5">
                <img src={about_banner} alt="about_banner" className="about_banner_img" />
              </div>
            </div>
          </div>
        </div>

        <div className="bg-black" style={{ padding: 80 }}>
          <div className="row">
            <div className="col-md-4 col-sm-12 col-lg-4 col-xl-4 col-xs-12 analytics_view">
              <img src={medal} alt="medal" />
              <p className="request_text">We offer excellent quality assignment solutions at an affordable price.</p>
            </div>
            <div className="col-md-4 col-sm-12 col-lg-4 col-xl-4 col-xs-12 analytics_view">
              <img src={cpu} alt="cpu" />
              <p className="request_text">We use analytical and critical thinking skills when developing an assignment solution.</p>
            </div>
            <div className="col-md-4 col-sm-12 col-lg-4 col-xl-4 col-xs-12 analytics_view">
              <img src={modal} alt="modal" />
              <p className="request_text">We deliver high-quality academic content for more than 38 subjects.</p>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <p className="header_sub_font pb-4 mt-5" style={{ padding: 25 }}>Our experts ensure that they understand the expectations of the tutors and provide the students with customised assignment solutions on time. Also, you need not worry about issues like plagiarism and integrity because the experts double quality check assignments before submitting them to the student. Moreover, if you need any changes, or revisions in the task. We understand that academic assignments are just not about writing but it involves proper research along with quality checks to ensure that the tasks are done efficiently. We offer assignment service help at the most reasonable price, while also providing support to the students to prepare the assignments correctly. Our team of esteemed industry professionals has assisted the students to achieve remarkable milestones by delivering high-quality homework assignments on time.</p>
          </div>
        </div>

      </React.Fragment>
    );
  }
}

export default About;
