import React from 'react'
import { Link } from 'react-router-dom'
import Samples_widget from '../Samples_widget/Samples_widget'

function Refund_cancellation_policy() {
  return (
    <>
      {/* <!-- Header Start --> */}
      <div class="container-fluid bg-primary mb-5">
        <div
          class="d-flex flex-column align-items-center justify-content-center"
          style={{ minHeight: "400px" }}
        >
          <h3 class="display-3 font-weight-bold text-white">Refund and Cancellation Policy</h3>
          <div class="d-inline-flex text-white">
            <p class="m-0">
              <Link class="text-white" to="/">
                Home
              </Link>
            </p>
            <p class="m-0 px-2">/</p>
            <p class="m-0">Refund and Cancellation Policy</p>
          </div>
        </div>
      </div>
      {/* <!-- Header End --> */}



      <section class="feature-area section-gap" id="service" style={{ textAlign: 'justify' }}>
        <div class="container">
          <div class="row d-flex justify-content-center">
            <div class="col-md-8 pb-20 header-text">
              <h1>Refund and Cancellation Policy</h1>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-8 col-sm-12 col-xs-12" id="container-content-wrapper">
              <p>Assignment Service Australia offers 100% refund for any assignment solution that has not met the expectation of client or user or has failed to deliver as per the client’s or user’s requirement. But for any refund the company needs proof of the fact that the solution/paper provided has actually not met the requirements. Such proofs include, but are not limited to, the grade sheet, instructor’s feedback, etc. Until and unless such proofs are provided, the company is not liable to refund the amount paid for the concerned assignment/project. The company offers 100% free revision of any solution/paper as per the feedback received until the requirements are properly met, but if it is proved that the feedback has mentioned points or requirements that were not initially furnished by the client or user then additional charges will be imposed for such revision, considering the extra work that needs to be done. Moreover, no refund will be entertained for any assignment that has a deadline of less than 48 hours. No refund will be entertained after 30 days of the order delivery. Refund amount will vary from 100% to 50% if the order was confirmed and the expert started working on the order but the client or user decided to cancel in the midway. In case the expert/tutor was confirmed and over half of the remaining deadline passed but then also the client or user decided to cancel the order then the refund amount will vary between 50% and 0% and this variation depends on the time tenure in such cases.  This refund policy is meant for covering the efforts of the company.<br></br>

                In case the client or user changes his/her mind and wants to cancel the order before any expert/tutor is assigned to the concerned assignment/project, the client is allowed to book other assignment help of the same value, free of cost. But in case the assignment/project is assigned to a respective expert/tutor and the client or user wants to cancel the same then such cancellation request will not be entertained for ensuring that the concerned expert/tutor receives legitimate compensation for his/her work. If for any unforeseen circumstances the company has to cancel an order after accepting the same then the company will issue a full refund to the client or user. The company reserves the right to cancel any order if there is a policy violation on the part of the client; if the feedback service is found to be deliberately misused by the client or user (in which case no refund will be issued); and if, in case of partial payment, the remaining payment has not been made in a timely manner within the planned timeline and there is no communication from the client or user (in which case no refund will be entertained). Any cancellation request has to be timely communicated via email to the support team’s email id <a target="_blank" href="mailto:support@assignmentserviceaustralia.com">support@assignmentserviceaustralia.com</a></p>

            </div>

            <div class="col-lg-4 sidebar">
              <Samples_widget />

            </div>

          </div>
        </div>
      </section>
    </>
  )
}

export default Refund_cancellation_policy
