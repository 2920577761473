

import React, { useEffect, useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import logo from './../../../assets/img/NewLogo.jpeg'
import * as Constant from "../../Constant";
import { BiLogoFacebook, BiLogoInstagram, BiLogoTwitter } from 'react-icons/bi';
import ReactGA from "react-ga";

function Header() {
  const [data, setStorePrimary] = useState();
  const [secondary, setStoreSecondary] = useState();
  const [tertiary, setStoreTertiary] = useState();
  const [headerfont, setStoreHeaderfont] = useState();
  const [fontcolor, setStoreFont] = useState();
  const [fontbanner, setStoreFontBanner] = useState();
  const [headerhoverfont, setHeaderFontHover] = useState();
  const [bannerformcolor, setBannerformColor] = useState();
  const [bottombannercolor, setBotttonBannerColor] = useState();

  const setGA = () => {
    console.log("setga");
    ReactGA.initialize("GTM-KJZ9L9PB", { debug: true }); //New
    ReactGA.pageview(window.location.pathname + window.location.search);
  };

  useEffect(() => {
    setGA();
    getJournal();
    localStorage.setItem('primary', data);
    localStorage.setItem('secondary', secondary);
    localStorage.setItem('tertiary', tertiary);
    localStorage.setItem('headerfont', headerfont);
    localStorage.setItem('fontcolor', fontcolor);
    localStorage.setItem('headerhoverfont', headerhoverfont);
    localStorage.setItem('bannerformcolor', bannerformcolor);
    localStorage.setItem('bottombannercolor', bottombannercolor);

    var colorr = localStorage.getItem('primary');
    document.documentElement.style.setProperty('--dynamic-color-primary', colorr);
    var colorr2 = localStorage.getItem('secondary');
    document.documentElement.style.setProperty('--dynamic-color-secondary', colorr2);
    var colorr3 = localStorage.getItem('tertiary');
    document.documentElement.style.setProperty('--dynamic-color-tertiary', colorr3);
    var colorr4 = localStorage.getItem('headerfont');
    document.documentElement.style.setProperty('--dynamic-color-headerfont', colorr4);
    var colorr5 = localStorage.getItem('fontcolor');
    document.documentElement.style.setProperty('--dynamic-color-fontcolor', colorr5);
    var colorr6 = localStorage.getItem('fontbanner');
    document.documentElement.style.setProperty('--dynamic-color-fontbanner', colorr6);
    var colorr7 = localStorage.getItem('headerhoverfont');
    document.documentElement.style.setProperty('--dynamic-color-headerhoverfont', colorr7);
    var colorr8 = localStorage.getItem('bannerformcolor');
    document.documentElement.style.setProperty('--dynamic-color-bannerformcolor', colorr8);
    var colorr9 = localStorage.getItem('bottombannercolor');
    document.documentElement.style.setProperty('--dynamic-color-bottombannercolor', colorr9);
  }, [data, secondary, tertiary, headerfont, fontcolor, fontbanner, headerhoverfont, bannerformcolor, bottombannercolor]);


  const getJournal = async () => {
    const response = await fetch(Constant.ColorUrl);
    const data = await response.json();
    // setDynamicColor(data.data.primary)
    updateDynamicColorPrimary(data.data.primary);
    updateDynamicColorSecondary(data.data.secondary);
    updateDynamicColorTertiary(data.data.tertiary);
    updateDynamicColorHeaderfont(data.data.headerfont);
    updateDynamicColorFont(data.data.fontcolor);
    updateDynamicColorFontBanner(data.data.fontbanner);
    updateDynamicColorHeaderFontHover(data.data.headerhoverfont);
    updateDynamicColorBannerFormColor(data.data.bannerformcolor);
    updateDynamicColorBottonBannerColor(data.data.bottombannercolor);
    setStorePrimary(data.data.primary);
    setStoreSecondary(data.data.secondary);
    setStoreTertiary(data.data.tertiary);
    setStoreHeaderfont(data.data.headerfont);
    setStoreFont(data.data.fontcolor);
    setStoreFontBanner(data.data.fontbanner);
    setHeaderFontHover(data.data.headerhoverfont);
    setBannerformColor(data.data.bannerformcolor);
    setBotttonBannerColor(data.data.bottombannercolor);
    // setJournal(data.data);
  };

  const updateDynamicColorPrimary = (color) => {
    // Update the dynamic color in CSS variables
    var colorr = localStorage.getItem('primary');
    if (colorr === colorr) {
      var colorr = localStorage.getItem('primary');
      document.documentElement.style.setProperty('--dynamic-color-primary', colorr);
    }
    document.documentElement.style.setProperty('--dynamic-color-primary', color);
  };
  const updateDynamicColorSecondary = (color2) => {
    // Update the dynamic color in CSS variables
    var colorr2 = localStorage.getItem('secondary');
    if (colorr2 === colorr2) {
      var colorr2 = localStorage.getItem('secondary');
      document.documentElement.style.setProperty('--dynamic-color-secondary', colorr2);
    }
    document.documentElement.style.setProperty('--dynamic-color-secondary', color2);
  };
  const updateDynamicColorTertiary = (color3) => {
    // Update the dynamic color in CSS variables
    var colorr3 = localStorage.getItem('tertiary');
    if (colorr3 === colorr3) {
      var colorr3 = localStorage.getItem('tertiary');
      document.documentElement.style.setProperty('--dynamic-color-tertiary', colorr3);
    }
    document.documentElement.style.setProperty('--dynamic-color-tertiary', color3);
  };
  const updateDynamicColorHeaderfont = (color4) => {
    var colorr4 = localStorage.getItem('headerfont');
    if (colorr4 === colorr4) {
      var colorr4 = localStorage.getItem('headerfont');
      document.documentElement.style.setProperty('--dynamic-color-headerfont', colorr4);
    }
    document.documentElement.style.setProperty('--dynamic-color-headerfont', color4);
  };
  const updateDynamicColorFont = (color5) => {
    var colorr5 = localStorage.getItem('fontcolor');
    if (colorr5 === colorr5) {
      var colorr5 = localStorage.getItem('fontcolor');
      document.documentElement.style.setProperty('--dynamic-color-fontcolor', colorr5);
    }
    document.documentElement.style.setProperty('--dynamic-color-fontcolor', color5);
  };

  const updateDynamicColorFontBanner = (color6) => {
    var colorr6 = localStorage.getItem('fontbanner');
    if (colorr6 === colorr6) {
      var colorr6 = localStorage.getItem('fontbanner');
      document.documentElement.style.setProperty('--dynamic-color-fontbanner', colorr6);
    }
    document.documentElement.style.setProperty('--dynamic-color-fontbanner', color6);
  };

  const updateDynamicColorHeaderFontHover = (color7) => {
    var colorr7 = localStorage.getItem('headerhoverfont');
    if (colorr7 === colorr7) {
      var colorr7 = localStorage.getItem('headerhoverfont');
      document.documentElement.style.setProperty('--dynamic-color-headerhoverfont', colorr7);
    }
    document.documentElement.style.setProperty('--dynamic-color-headerhoverfont', color7);
  };
  const updateDynamicColorBannerFormColor = (color8) => {
    var colorr8 = localStorage.getItem('bannerformcolor');
    if (colorr8 === colorr8) {
      var colorr8 = localStorage.getItem('bannerformcolor');
      document.documentElement.style.setProperty('--dynamic-color-bannerformcolor', colorr8);
    }
    document.documentElement.style.setProperty('--dynamic-color-bannerformcolor', color8);
  };

  const updateDynamicColorBottonBannerColor = (color9) => {
    var colorr9 = localStorage.getItem('bottombannercolor');
    if (colorr9 === colorr9) {
      var colorr9 = localStorage.getItem('bottombannercolor');
      document.documentElement.style.setProperty('--dynamic-color-bottombannercolor', colorr9);
    }
    document.documentElement.style.setProperty('--dynamic-color-bottombannercolor', color9);
  };

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <div>
        <div class="container-fluid position-relative shadow background">
          <nav
            class="navbar navbar-expand-lg  navbar-light py-3 py-lg-0 px-0 px-lg-5">
            <NavLink to='/' $item>
              <img src={logo} width={100} alt='wait' />
            </NavLink>
            <button
              type="button"
              class="navbar-toggler"
              data-toggle="collapse"
              data-target="#navbarCollapse"
            >
              <span class
                className="navbar-toggler-icon"></span>
            </button>
            <div
              class="collapse navbar-collapse justify-content-between collapse"
              id="navbarCollapse" style={{ marginRight: "-16%" }}
            >
              <div class="navbar-nav font-weight-bold mx-auto py-0 d-sm-flex d-none">
                <NavLink className="nav-item nav-link Headerfont" to='/' onClick={goToTop}>Home</NavLink >
                <NavLink className="nav-item nav-link Headerfont" to="/about" onClick={goToTop}>About</NavLink >

                <div class="nav-item dropdown" style={{ cursor: "pointer" }}>
                  <Link className="nav-link dropdown-toggle Headerfont"
                    data-toggle="dropdown">Services</Link>
                  <div class="dropdown-menu rounded-0 m-0 background">
                    <Link className="dropdown-item" to="/essay">Essay</Link>
                    <Link className="dropdown-item" to="/dissertation">Dissertation</Link>
                    <Link className="dropdown-item" to="/programming">Programming</Link>
                    <Link className="dropdown-item" to="/networking">Networking</Link>
                    <Link className="dropdown-item" to="/research">Research</Link>
                    <Link className="dropdown-item" to="/cdr">CDR</Link>
                    <Link className="dropdown-item" to="/proofreading">Proofreading</Link>
                    <Link className="dropdown-item" to="/mba">MBA</Link>
                  </div>
                </div>
                {/* <NavLink className="nav-item nav-link Headerfont" to="/samples" onClick={goToTop}>Samples</NavLink> */}
                <NavLink className="nav-item nav-link Headerfont" to="/reviews" onClick={goToTop}>Reviews</NavLink>
                <NavLink className="nav-item nav-link Headerfont" to="/contact" onClick={goToTop}>Contact</NavLink>

                <div style={{ marginTop: '22px', marginLeft: '122px' }}>
                  <a
                    class="btn btn-outline-primary rounded-circle text-center mr-2 px-0 text-white"
                    // style="width: 38px; height: 38px"
                    style={{ width: '38px', height: '38px' }}
                    href="https://twitter.com/asahelps" target='blank'
                  ><i> <BiLogoTwitter style={{ fontSize: '1.3em' }} /></i></a>
                  <a
                    class="btn btn-outline-primary rounded-circle text-center mr-2 px-0 text-white"
                    style={{ width: '38px', height: '38px' }}
                    href="https://www.facebook.com/asahelps" target='blank'
                  ><i> <BiLogoFacebook style={{ fontSize: '1.3em' }} /> </i></a>
                  <a
                    class="btn btn-outline-primary rounded-circle text-center mr-2 px-0 text-white"
                    style={{ width: '38px', height: '38px' }}
                    href="https://www.instagram.com/asahelps/" target='blank'
                  ><i> <BiLogoInstagram style={{ fontSize: '1.3em' }} /> </i></a>
                </div>
              </div>


              <div class="navbar-nav font-weight-bold mx-auto py-0 d-sm-none  d-block">
                <a className="nav-item nav-link Headerfont" activeClassName="active" href='/'>Home</a>
                <a className="nav-item nav-link Headerfont" activeClassName="active" href="/about">About</a>
                <a className="nav-item nav-link Headerfont" activeClassName="active" href="/reviews">Reviews</a>
                <div class="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle Headerfont"
                    data-toggle="dropdown">Services</Link>
                  <div class="dropdown-menu rounded-0 m-0 background">
                    <a className="dropdown-item" href="/essay">Essay</a>
                    <a className="dropdown-item" href="/dissertation">Dissertation</a>
                    <a className="dropdown-item" href="/programming">Programming</a>
                    <a className="dropdown-item" href="/networking">Networking</a>
                    <a className="dropdown-item" href="/research">Research</a>
                    <a className="dropdown-item" href="/cdr">CDR</a>
                    <a className="dropdown-item" href="/proofreading">Proofreading</a>
                    <a className="dropdown-item" href="/mba">MBA</a>
                  </div>
                </div>
                {/* <a className="nav-item nav-link Headerfont" href="/samples">Samples</a> */}
                <a className="nav-item nav-link Headerfont" href="/contact">Contact</a>

                <div class="d-flex justify-content-start mt-4">
                  <a
                    class="btn btn-outline-primary rounded-circle text-center mr-2 px-0 text-white"
                    // style="width: 38px; height: 38px"
                    style={{ width: '38px', height: '38px' }}
                    href="https://twitter.com/asahelps" target='blank'
                  ><i> <BiLogoTwitter style={{ fontSize: '1.3em' }} /></i></a>
                  <a
                    class="btn btn-outline-primary rounded-circle text-center mr-2 px-0 text-white"
                    style={{ width: '38px', height: '38px' }}
                    href="https://www.facebook.com/asahelps" target='blank'
                  ><i> <BiLogoFacebook style={{ fontSize: '1.3em' }} /> </i></a>
                  <a
                    class="btn btn-outline-primary rounded-circle text-center mr-2 px-0 text-white"
                    style={{ width: '38px', height: '38px' }}
                    href="https://www.instagram.com/asahelps/" target='blank'
                  ><i> <BiLogoInstagram style={{ fontSize: '1.3em' }} /> </i></a>
                </div>
              </div>
              {/* <a href="" class="btn btn-primary px-4">Join Class</a> */}
            </div>
          </nav>
        </div>
      </div>
    </>
  )
}

export default Header
