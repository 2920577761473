import React from 'react'
import mba_banner from '../../../assets/img/newHome/mba_banner.png'
import './MBA_service.css'
import MBA_graph from '../../../assets/img/newHome/MBA_graph.png'


function MBA_service() {
  return (
    <>
      <div className="prf_banner">
        <div className="container">
          <div className="row ">
            <div className="col-md-6 col-lg-6 col-xs-12 col-sm-12 col-xl-6" >
              <p className="header_font" style={{ fontSize: 84 }}>Get Experts for  <span style={{ color: '#FEC00F' }}>MBA Assignments </span></p>
              <p className="header_sub_font pb-4" >Assignment service Australia would provide trusted professionals who have great experience in the field of management.</p>
              <a href="/contact" className="get_started ">Contact Us&nbsp;&nbsp;
                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="14" viewBox="0 0 17 14" fill="none">
                  <path d="M16.7923 7.49522L10.4175 13.7949C10.2846 13.9262 10.1043 14 9.91634 14C9.72838 14 9.54812 13.9262 9.41521 13.7949C9.2823 13.6635 9.20764 13.4854 9.20764 13.2996C9.20764 13.1139 9.2823 12.9358 9.41521 12.8044L14.5814 7.69996H0.70831C0.520455 7.69996 0.340293 7.62621 0.207459 7.49495C0.0746255 7.36368 0 7.18564 0 7C0 6.81436 0.0746255 6.63632 0.207459 6.50505C0.340293 6.37378 0.520455 6.30004 0.70831 6.30004H14.5814L9.41521 1.19557C9.2823 1.06423 9.20764 0.886095 9.20764 0.70035C9.20764 0.514606 9.2823 0.336469 9.41521 0.205128C9.54812 0.0737866 9.72838 0 9.91634 0C10.1043 0 10.2846 0.0737866 10.4175 0.205128L16.7923 6.50478C16.8581 6.56978 16.9104 6.64698 16.946 6.73196C16.9817 6.81693 17 6.90801 17 7C17 7.09198 16.9817 7.18307 16.946 7.26804C16.9104 7.35302 16.8581 7.43021 16.7923 7.49522Z" fill="black" />
                </svg></a>
            </div>
            <div className="col-md-1 col-lg-1 col-xs-12 col-sm-12 col-xl-1"></div>
            <div className="col-md-5 col-lg-5 col-xs-12 col-sm-12 col-xl-5">
              <img src={mba_banner} className='about_banner_img' />
            </div>
          </div>
        </div>
      </div>

      <div className="bg-black">
        <div className="row">
          <div className="col-md-3 col-sm-12 col-lg-3 col-xl-3 col-xs-12">
            <p className="number">12,958</p>
            <p className="request_text">Student requests</p>
          </div>
          <div className="col-md-3 col-sm-12 col-lg-3 col-xl-3 col-xs-12">
            <p className="number">2,468</p>
            <p className="request_text">Assignments completed</p>
          </div>
          <div className="col-md-3 col-sm-12 col-lg-3 col-xl-3 col-xs-12">
            <p className="number">2,378</p>
            <p className="request_text">Technical projects</p>
          </div>
          <div className="col-md-3 col-sm-12 col-lg-3 col-xl-3 col-xs-12">
            <p className="number">1,273</p>
            <p className="request_text">In house professionals</p>
          </div>
        </div>
      </div>

      <p className="grades mt-5" >Best <span style={{ color: '#FEC00F' }}>MBA Help Service </span>in Australia</p>
      <div className='row' style={{ marginLeft: '10%', marginRight: '10%' }}>
        <p className="essay_text mt-3">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Felis bibendum ut tristique et. Scelerisque fermentum dui faucibus in ornare. Laoreet id donec ultrices tincidunt arcu non. Sapien eget mi proin sed libero enim. Habitant morbi tristique senectus et netus et malesuada fames ac. Egestas sed sed risus pretium quam vulputate dignissim. Cursus vitae congue mauris rhoncus. Suscipit adipiscing bibendum est ultricies. Dolor morbi non arcu risus quis varius quam quisque.</p>
        <p className="essay_text mt-1">Ac felis donec et odio pellentesque. In massa tempor nec feugiat nisl pretium fusce id. Lectus sit amet est placerat in egestas erat imperdiet. Rhoncus dolor purus non enim praesent elementum facilisis leo. Convallis convallis tellus id interdum. Hendrerit gravida rutrum quisque non tellus orci ac auctor augue. Tempus imperdiet nulla malesuada pellentesque elit eget gravida cum sociis. At consectetur lorem donec massa sapien faucibus et. Faucibus interdum posuere lorem ipsum.</p>
      </div>

      <p className="grades mt-5" >MBA assignment services for <span style={{ color: '#FEC00F' }}>Different programs </span></p>

      <div className='row' style={{ marginLeft: '10%', marginRight: '10%' }} >
        <div className='col-md-3 col-lg-3 col-sm-12 col-xl-3 col-xs-12'>
          <div className='mba_block'>
            <p className='block_head_text'>MBA (Full time)</p>
            <p className='block_sub_text'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Felis bibendum ut tristique et. Scelerisque fermentum dui faucibus in ornare. </p>
            <div style={{ display: 'flex', justifyContent: 'space-evenly', marginLeft: 0 }}>
              <a href="/contact" className="get_started ">Contact Us</a>
            </div>
          </div>
        </div>
        <div className='col-md-3 col-lg-3 col-sm-12 col-xl-3 col-xs-12'>
          <div className='mba_block'>
            <p className='block_head_text'>MBA (Part-time)</p>
            <p className='block_sub_text'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Felis bibendum ut tristique et. Scelerisque fermentum dui faucibus in ornare. </p>
            <div style={{ display: 'flex', justifyContent: 'space-evenly', marginLeft: 0 }}>
              <a href="/contact" className="get_started ">Contact Us</a>
            </div>
          </div>
        </div>
        <div className='col-md-3 col-lg-3 col-sm-12 col-xl-3 col-xs-12'>
          <div className='mba_block'>
            <p className='block_head_text'>MBA (Accelerated)</p>
            <p className='block_sub_text'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Felis bibendum ut tristique et. Scelerisque fermentum dui faucibus in ornare. </p>
            <div style={{ display: 'flex', justifyContent: 'space-evenly', marginLeft: 0 }}>
              <a href="/contact" className="get_started ">Contact Us</a>
            </div>
          </div>
        </div>
        <div className='col-md-3 col-lg-3 col-sm-12 col-xl-3 col-xs-12'>
          <div className='mba_block'>
            <p className='block_head_text'>MBA (Executives)</p>
            <p className='block_sub_text'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Felis bibendum ut tristique et. Scelerisque fermentum dui faucibus in ornare. </p>
            <div style={{ display: 'flex', justifyContent: 'space-evenly', marginLeft: 0 }}>
              <a href="/contact" className="get_started ">Contact Us</a>
            </div>
          </div>
        </div>
      </div>

      <p className="grades mt-5" style={{ color: '#FEC00F' }}>Courses offered <span style={{ color: '#000' }}>in MBA Assignment service </span></p>

      <div className='row pb-3' style={{ marginLeft: '10%', marginRight: '10%' }} >
        <div className='col-md-4 col-lg-4 col-sm-12 col-xl-4 col-xs-12'>
          <div className='mba_block'>
            <p>Marketing</p>
          </div>
        </div>
        <div className='col-md-4 col-lg-4 col-sm-12 col-xl-4 col-xs-12'>
          <div className='mba_block'>
            <p>Hospitality</p>
          </div>
        </div>
        <div className='col-md-4 col-lg-4 col-sm-12 col-xl-4 col-xs-12'>
          <div className='mba_block'>
            <p>Human
              resources</p>
          </div>
        </div>
        <div className='col-md-4 col-lg-4 col-sm-12 col-xl-4 col-xs-12'>
          <div className='mba_block'>
            <p>International
              business</p>
          </div>
        </div>
        <div className='col-md-4 col-lg-4 col-sm-12 col-xl-4 col-xs-12'>
          <div className='mba_block'>
            <p>Banking and
              Finance</p>
          </div>
        </div>
        <div className='col-md-4 col-lg-4 col-sm-12 col-xl-4 col-xs-12'>
          <div className='mba_block'>
            <p>Information and
              Technology</p>
          </div>
        </div>
      </div>

      <p className="grades mt-5">Why should you take services from </p>
      <p className="grades" style={{ color: '#FEC00F' }}>Assignment service Australia?</p>

      <div className='row' style={{ marginLeft: '10%', marginRight: '10%' }}>
        <p className="essay_text mt-3">Assignment service Australia is the leading writing service provider in Australia that is trusted by both graduates/post-graduate students. Students prefer taking services from us to avail excellent scores in their MBA course. The various reasons for taking services from us are stated in the following ways:</p>
      </div>


      <img src={MBA_graph} style={{ marginTop: 50, width: '100%' }} />



    </>
  )
}

export default MBA_service
