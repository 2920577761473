import React from 'react';
import $ from 'jquery';
import { Route, Routes } from "react-router-dom";
import Header from './components/layout/Header/Header';
import About from './components/About/About';
import Index from './components/Index/Index';
import Footer from './components/layout/Footer/Footer';
// import './../src/assets/css/style.css';
import './assets/css/style.css';
import Contact from './components/Contact/Contact';
import Samples from './components/Samples/Samples';
import Reviews from './components/Reviews/Reviews';
import Chat from './components/Chat';
import Faq from './components/Faq/Faq';
import Joinourteam from './components/Joinourteam/Joinourteam';
import How_it_works from './components/How_it_works/How_it_works';
import Blogs from './components/Blogs/Blogs';
import Terms_conditions from './components/Terms_conditions/Terms_conditions';
import Privacy_policy from './components/Privacy_policy/Privacy_policy';
import Refund_cancellation_policy from './components/Refund_cancellation_policy/Refund_cancellation_policy';
import Australia from './components/Australia/Australia';
import Newzealand from './components/Newzealand/Newzealand';
import Canada from './components/Canada/Canada';
import ThankYou from './components/layout/thankyou/ThankYou';
import Essay_service from './components/Services_menu/Essay_service/Essay_service';
import Dissertation_service from './components/Services_menu/Dissertation_service/Dissertation_service';
import Programming_service from './components/Services_menu/Programming_service/Programming_service';
import Networking_service from './components/Services_menu/Networking_service/Networking_service';
import Research_service from './components/Services_menu/Research_service/Research_service';
import CDR_service from './components/Services_menu/CDR_service/CDR_service';
import Proofreading_service from './components/Services_menu/Proofreading_service/Proofreading_service';
import MBA_service from './components/Services_menu/MBA_service/MBA_service';
import Samples_submenu from './components/Samples_submenu/Samples_submenu';
import Samples_widget from './components/Samples_widget/Samples_widget';
import Place_order from './components/Place_order/Place_order';
import Blogs_sub from './components/Blogs_sub/Blogs_sub';
import Order_now from './components/Order_now/Order_now';

function App() {
  return (
    <div>
      <Header />
      <Routes>
        <Route>
          <Route path="/" element={<Index />} />
          <Route path="about" element={<About />} />
          <Route path="reviews" element={<Reviews />} />
          <Route path="samples" element={<Samples />} />
          <Route path="Samples_submenu" element={<Samples_submenu />} />
          <Route path="Samples_widget" element={<Samples_widget />} />
          <Route path="contact" element={<Contact />} />
          <Route path="chat" element={<Chat />} />
          <Route path="faq" element={<Faq />} />
          <Route path='Joinourteam' element={<Joinourteam />} />
          <Route path='how_it_works' element={<How_it_works />} />
          <Route path='blogs' element={<Blogs />} />
          <Route path='blogs_sub' element={<Blogs_sub />} />
          <Route path='terms_conditions' element={<Terms_conditions />} />
          <Route path='privacy_policy' element={<Privacy_policy />} />
          <Route path='refund_cancellation_policy' element={<Refund_cancellation_policy />} />
          <Route path='australia' element={<Australia />} />
          <Route path='newzealand' element={<Newzealand />} />
          <Route path='canada' element={<Canada />} />
          <Route path='place_order' element={<Place_order />} />
          <Route path='order_now' element={<Order_now />} />
          <Route path='thankyou' element={<ThankYou />} />

          {/* services submenu pg */}
          <Route path='essay' element={<Essay_service />} />
          <Route path='dissertation' element={<Dissertation_service />} />
          <Route path='programming' element={<Programming_service />} />
          <Route path='networking' element={<Networking_service />} />
          <Route path='research' element={<Research_service />} />
          <Route path='cdr' element={<CDR_service />} />
          <Route path='proofreading' element={<Proofreading_service />} />
          <Route path='mba' element={<MBA_service />} />

          {/* <Route path="*" element={<NoPage />} /> */}
        </Route>
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
