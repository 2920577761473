import React from 'react'
import { Link } from 'react-router-dom'
import Samples_widget from '../Samples_widget/Samples_widget'

function Canada() {
    return (
        <>
            {/* <!-- Header Start --> */}
            <div class="container-fluid bg-primary mb-5">
                <div
                    class="d-flex flex-column align-items-center justify-content-center"
                    style={{ minHeight: "400px" }}
                >
                    <h3 class="display-3 font-weight-bold text-white">Best in countries</h3>
                    <div class="d-inline-flex text-white">
                        <p class="m-0">
                            <Link class="text-white" to="/">
                                Home
                            </Link>
                        </p>
                        <p class="m-0 px-2">/</p>
                        <p class="m-0">Canada</p>
                    </div>
                </div>
            </div>
            {/* <!-- Header End --> */}


            <section class="feature-area section-gap" id="service" style={{ textAlign: 'justify' }}>
                <div class="container">
                    <div class="row d-flex justify-content-center">
                        <div class="col-md-8 pb-20 header-text">
                            <h4>WE PROVIDE BEST ASSIGNMENT SERVICE IN CANADA</h4>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-8 col-sm-12 col-xs-12" id="container-content-wrapper">
                            <p>Assignment Service Australia also provides many services to the students that are studying in Canadian Universities. Canada is also known for formulating automobiles and hats business. Assignment Service Australia assists students by providing services for the subjects that they are studying in reputed institutions. Students get the facility to get the help from professionalized experts and writers. Thus, the team members enable to facilitate all the students in order to tackle all their academic writers.</p>
                            <div>
                                <h4>Education in Canada</h4>
                                <p class="mt-2">According to experts, Canada is one of the most efficient educational hubs around the world. Under the program of the Canadian government, all the institutions of Canada were enhanced with improved facilities, infrastructure, and innovative courses. The enhanced refurbishment that has been made enabled to make this city as the most popular among all the international students. Assignment service Australia aims to deliver the services to various institutes of Canada that includes University of Alberta, The University of British Columbia, University of Toronto, Humber College, McGill University, Centennial College and University of Windsor.</p>
                            </div>

                            <div>
                                <h4>The Services provided by Assignment Service Australia in Canada are as per the syllabus of the concerned university</h4>
                                <p class="mt-2">Among all the institutes stated above, The University of British Columbia is considered to be the most significant university. As per the figures provided by the experts, more than 18,000 students are currently studying in The University of British Columbia. The objective of Assignment Service Australia is to provide assistance to the students that are studying in different universities of Canada.</p>
                            </div>
                            <div>
                                <h4>How Assignment Service Australia help students of Canada?</h4>
                                <div>Every student that is studying, faces several difficulties in order to complete their tasks in the desired manner. The team members of Assignment Service Australia are ready to help the students that are studying in Canada and provide them with a sigh of relief by providing them with help in completing their assignments. Below highlight the various reasons for availing help from Assignment Service Australia by students:
                                    <ul class="about-list">
                                        <li>Due to fear of the deadline.</li>
                                        <li>Inefficiency in managing various assignments at one time.</li>
                                        <li>Only basic knowledge regarding a particular subject.</li>
                                        <li>Unavailability of resources</li>
                                    </ul>
                                </div>
                            </div>
                            <div>
                                <h4></h4>
                                <div class="mt-2">
                                    Well, if you want to seek any kind of academic help, then you are at right place. We have been serving students of Canada from the past several years. We guarantee to provide high quality and satisfactory results to the students. Assignment service Australia majorly deals with the following subjects:
                                    <ul class="about-list mt-2">
                                        <li><b class="bold">Marketing : </b>
                                            Marketing subject majorly deals with the buying and selling of goods and services in the market.
                                        </li>
                                        <li><b class="bold">Management : </b>
                                            The objective of management subject is to achieve the desired goal of an organization.
                                        </li>
                                        <li><b class="bold">Information technology : </b>
                                            The subject of Information technology majorly deals with
                                            computers and all other different activities concerned with computational systems.
                                        </li>
                                        <li><b class="bold">Healthcare : </b>
                                            The subject of healthcare majorly deals with the health care services to the individuals in order to provide them a healthy and high-quality life.
                                        </li>
                                        <li><b class="bold">Accountancy : </b>
                                            The subject of Accountancy deals with all the aspects that majorly
                                            covers analysis and reporting of numerous financial accounts.
                                        </li>
                                        <li><b class="bold">Law : </b>
                                            Law represents the various governmental rules and aims to ensure the regulatory behaviour of people. Along with this, it also ensures that community members should follow all such rules and regulations.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>


                        <div class="col-lg-4 sidebar">
                            <Samples_widget />
                        </div>







                    </div>
                </div>
            </section>
        </>
    )
}

export default Canada
